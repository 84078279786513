import CalculateExpiredPermitMoreThan90Days from "../EndModule/CalculateExpiredPermitMoreThan90Days";

/* eslint-disable @typescript-eslint/ban-types */
export const jsonArray: {}[] = [];

export const keys: {[key: string]: string} = {

    typeOfApplication:"Tipo de solicitud",
    type:"Tipo de familiar",
    sponsorship:"¿A cúal familiar desea patrocinar?",
    programAplication:"¿Programa a que desea aplicar?",
    casePGWP:"Caso PGWP",
    purposeVisit:"Proposito visita",
    typeOfStudyPermit:"Tipo de permiso de estudios",
    typeOfApplicationOfStudyPermit:"Tipo de aplicacion permiso de estudios",
    lessThanSixMonths:"¿Planea estudiar un curso de menos de 6 meses?",
    typeOfWorkPermit:"Tipo de permito de trabajo",
    labourMarketImpactAssessment:"¿Desea incluir los gastos de validación por su empleador?",
    typeOfStatusConyuge:"Estatos conyugue",
    province:"Provincia de destino",
    havePreviousUSAVisa:"¿Tiene visa vigente de USA o visa reciente de Canada?",
    wantsTemporaryResidenceService:"¿Desea añadir el servicio de visa de residente temporal?",
    wantCaq:"¿Desea agregar el servicio CAQ?",
    typeOfClosedWorkPermit:"Tipo de permiso de trabajo cerrado",
    wantAddLmiaPositive:"¿Empleador desea hacer sol. LMIA?",
    wantValidationLmiaExemptJobOffer:"¿Empleador desea hacer val.oferta de trabajo exenta de LMIA?",
    inCanada:"¿Esta dentro de Canada?",
    ExpiredPermit: "¿Su permiso actual vence en los próximos 20 días hábiles?",
    ExpiredPermitMoreThan90Days: "¿Su permiso actual vencio en 60 a 90 dias?",
    migratoryStatus:"Estatus migratorio en Canada",
    validcoopLetter:"¿Tiene carta que confirme que el co-op es requisito mandatorio de su programa de estudios?",
    wayChooseOnStudy:"Camino seleccionado en estudio",
    lessThanSixCaq:"¿Su programa de idiomas tiene una duración de 24 semanas o más o su carta de aceptación le exige obtener un CAQ?",
    acceptedLOAInstitution:"¿Aceptado en una DLI?",
    StatusRestoration:"tarifa por restauración de permiso vencido", 
    ExpiredPermitDate:"Fecha de vencimiento del permiso abierto de trabajo",
    languageRequired:"¿Su pareja es elegible para aplicar a un permiso abierto de trabajo?",
    studyOption:"¿desea servicio CAQ?",
    scenery:"Escenario",
    name: "Nombre",
    lastname: "Apellidos",
    passportCountry:"Pais emisor de pasaporte",
    canadianChildren:"¿Tiene hijos o nietos residentes permanentes o ciudadanos canadienses?",
    countryOfCitizenship:"País emisor de su pasaporte",
    countryOfCurrentResidency:"Pais de residencia",
    age: "Edad",
    birthdayFormatted:"Fecha de nacimiento",
    civilStatus:"Estado civil",
    wantsAddConyugue:"¿Quiere agregar al conyugue?",
    ApplyWithSpouse:"¿Va a aplicar con conyugue?",
    elegiblePGWP:"¿Es elegible para un PGWP?",
    kindOfTeer:"Tipo de Teer",
    appliedBefore:"¿Previamente ha aplicado para entrar o permanecer en Canadá?" ,
    validBiometrics:"¿Tiene biométricos válidos?",
    canadianVisaRejection:"¿Ha tenido rechazos previos con Canadá del mismo tipo de solicitud a la que desea aplicar?",
    wantsAtip:"¿Aplico por atip?",
    AtipFee:"¿Tiene tarifa por atip?",
    email:"Email",
    confirmEmail:"Confirmacion de email",
    address:"Direccion",
    phoneNumber:"Numero de telefono",
    validacademicRecord:"¿Proximo o Completado su programa de estudios?",
    principalHasPathway:"¿Tiene pathway?",
    isPreviousClient:"¿Anteriormente ha realizado algún proceso de visado con Immiland?",
    contractEnvelopeID:"Envelope ID",
    validContract:"Contrato valido",
    discountCode:"Codigo de descuento",
    hasLoa:"¿Tiene carta de aceptacion?",
    haveEta:"¿Usted tiene ETA?",
    addEta:"¿Quiere agregar ETA?",
    wantsBP:"¿Quiere aplicar por border package?",
    wantsDependantChildren:"¿Quiere agregar hijos?",
    howManyDependantChild:"¿Cuanto hijos quiere agregar?",
    moreThan18:"¿Tiene más de 18 años?",
    isRecident:"¿Es ciudadano Canadiense o residente permanente de Canadá?",
    isDisability:"¿Está en asistencia social por cualquier otra razón que no sea discapacidad?",
    addOpenWork:"¿Desea adjuntar un permiso de trabajo abierto para su pareja?",
    phase:"Fase",
    phaseexpress:"Fase express entry",
    pnpprogram:"Programa PNP",
    phasepnp:"Fase PNP",
    expresion:"¿A qué desea aplicar? Expresión de interés",
    csq:"¿A qué desea aplicar?  csq",
    rpcsq:"¿A qué desea aplicar? PR csq",
    csqPEQ:"¿A qué desea aplicar? CSQ PEQ",
    rpcsqPEQ:"¿A qué desea aplicar? PR CSQ PEQ",
    pn:" ¿A qué desea aplicar? Nominación provinciaL",
    prendorsement:"¿A qué desea aplicar? Residencia permanente",
    addCSQ:"¿Desea añadir el servicio CSQ?",
    addCSQChildren:"¿Desea anadir el servicio de CSQ para sus hijos?",
    employerName:'Nombre del empleador',
    employerEmail:'Email del empleador',
    companyName:'Nombre de la compañia',
    employletter:'¿Desea agregar  la redacción de una carta  de oferta laboral?',
    ResponsableValidacionLMIA: 'Validador oferta laboral'
   };
