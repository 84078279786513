/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCAQ: React.FC<IProps> = ({
	products
}) => {
	const caq = products.find(element => element.contratationProductId === "38")
	const rootStore = useContext(RootStoreContext)
	const { familyMembers, principalUsed, setCalculateCAQStudent, getCalculateCAQStudent } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.inCanada === "no" &&
			member.type == FamilyTypes.principal &&
			member.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type

			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.inCanada === "no" &&
			(principalUsed()?.purposeVisit === "Trabajo" ||
				principalUsed()?.purposeVisit === "Estudios") &&
			member.type == FamilyTypes.children &&
			principalUsed()?.province === 'Quebec' &&
			principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"  &&
			member.ageForCAQMinor === false &&
			member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si" &&
			(principalUsed()?.typeOfApplication === "Work Visa Extension" ||
				principalUsed()?.typeOfApplication === "Study Visa Extension")
			&& member.type == FamilyTypes.children &&
			principalUsed()?.province === 'Quebec' &&
			principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"  &&
			member.ageForCAQMinor === false &&
			member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


		if (principalUsed()?.inCanada === "si" &&
			member.type == FamilyTypes.principal &&
			member.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"  &&
			member.wayChooseOnStudy !== 'StudyLessSix' &&
			member.typeOfApplication === "Study Visa Extension") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})

	if (caq !== undefined) {
		setCalculateCAQStudent({
			id: caq.id,
			productContratationId: caq.contratationProductId,
			description: caq.description,
			totalFee: countCAQ * caq.fee,
			name: caq.name,
			quantity: countCAQ,
			fee: caq.price,
			memberInfo: arrayOfMembers
		})
	}

	return (
		<>
			{getCalculateCAQStudent().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculateCAQStudent().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCalculateCAQStudent().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculateCAQStudent().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculateCAQStudent().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCAQ)
