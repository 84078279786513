/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {useContext, useEffect, useState} from 'react'
import {Button, Table, TableBody, TableRow} from 'Components'
import html2canvas from 'html2canvas'
import WarningIcon from './warning.svg'
import CalculateBiometrics from './CalculateBiometrics'
import CalculateCanadaTaxFee from './CalculateCanadaTaxFee'
import CalculateEta from './CalculateEta'
import CalculateTotalsNew from './CalculateTotals'
import CalculateTransaccionFee from './CalculateTransaccionFee'
import Resumen from './Resumen'
import {RootStoreContext} from 'stores/rootStore'
import {observer} from 'mobx-react'
import {sendAnswersInfo} from 'modules/flows/contractsTemps/_request'
import DiscountCode from './DiscountCode'
import CalculateVisitorVisa from './CalculateVisitorVisa'
import CalculateVisitorFederalFee from './CalculateVisitorFederalFee'
import CalculateTransitVisa from './CalculateTransitVisa'
import CalculateSuperVisa from './CalculateSuperVisa'
import CalculateDependantLessFive from './CalculateDependantLessFive'
import CalculateSubmission from './CalculateSubmission'
import CalculateEtaFederalFee from './CalculateEtaFedera'
import CalculateSuperVisaFederalFee from './CalculateSuperVisaFederalFee'
import {Product} from 'modules/flows/products/module'
import CalculateStudyPermit from './CalculateStudyPermitAcademic'
import CalculateCAQStudent from './CalculateCAQStudent'
import CalculateCAQExtentionStudent from './CalculateCAQExtentionStudent'
import CalculateStudyPermitFederalFee from './CalculateStudyPermitFederalFee'
import CalculateStudyPermitLang from './CalculateStudyPermitLang'
import CalculateStudyPermitStream from './CalculateStudyPermitStream'
import CalculateCAQStudentGovernmentFee from './CalculateCAQStudentGovernmentFee'
import CalculatePartnerOpenWorkSpouseON from './CalculatePartnerOpenWorkSpouseON'
import CalculateDependantPlusFive from './CalculateDependantPlusFive'
import CalculateOpenWorkPermitHolderFederal from './CalculateOpenWorkPermitHolderFederal'
import CalculateWorkPermitIncludesExtentionFederalFee from './CalculateWorkPermitIncludesExtentionFederalFee'
import CalculatePartnerWorkPermitPOE from './CalculatePartnerWorkPermitPOE'
import CalculateWorkPermitExemptON from './CalculateWorkPermitExemptON'
import CalculateWorkPermitExemptPOE from './CalculateWorkPermitExemptPOE'
import CalculateWorkPermitPositiveON from './CalculateWorkPermitPositiveON'
import CalculateWorkPermitPositivePOE from './CalculateWorkPermitPositivePOE'
import CalculateVisitorRecord from './CalculateVisitorRecord'
import CalculateVisitorRecordFederalFee from './CalculateVisitorRecordFederalFee'
import CalculateWorkPermitHoliday from './CalculateWorkPermitHoliday'
import CalculateCAQMinor from './CalculateCAQMinor'
import CalculateCAQMinorGovernmentFee from './CalculateCAQMinorGovernmentFee'
import CalculateCAQWorker from './CalculateCAQWorker'
import CalculateCAQWorkerGovernmentFee from './CalculateCAQWorkerGovernmentFee'
import CalculateDependantStudyOverFiveExtention from './CalculateDependantStudyOverFiveExtention'
import CalculateDependantStudylessFiveExtention from './CalculateDependantStudylessFiveExtention'
import CalculateSpouseOpenWorkPermitExtentionON from './CalculateSpouseOpenWorkPermitExtentionON'
import CalculateStudyPermitExtensionON from './CalculateStudyPermitExtensionON'
import CalculateTravelDocumentRenovation from './CalculateTravelDocumentRenovation'
import CalculateSponsorshipApplicationIn from './CalculateSponsorshipApplicationIn'
import CalculatePermanentResidenceSponsorshipFederalFee from './CalculatePermanentResidenceSponsorshipFederalFee'
import CalculateOpenWorkPermitMainApplicant from './CalculateOpenWorkPermitMainApplicant'
import CalculateCSQ from './CalculateCSQ'
import CalculateCSQgovernmentApplicationFeeMainApplicant from './CalculateCSQgovernmentApplicationFeeMainApplicant'
import CalculateCSQGovernmentApplicationFeeDependentChild from './CalculateCSQGovernmentApplicationFeeDependentChild'
import CalculatePermanentResidenceSponsorshipFederalFeeDepentantChildIncluded from './CalculatePermanentResidenceSponsorshipFederalFeeDepentantChildIncluded'
import CalculateExpressEntryExpressionOfInterestPhase1 from './CalculateExpressEntryExpressionOfInterestPhase1'
import CalculateSpousePartnerExpressEntryExpressionOfInterestPhase1 from './CalculateSpousePartnerExpressEntryExpressionOfInterestPhase1'
import CalculateSponsorshipApplicationOut from './CalculateSponsorshipApplicationOut'
import CalculatePostGraduationWorkPermitPOE from './CalculatePostGraduationWorkPermitPOE'
import CalculatePostGraduationWorkPermitON from './CalculatePostGraduationWorkPermitON'
import CalculatePostGraduateWorkPermitExtention from './CalculatePostGraduateWorkPermitExtention'
import CalculateCoopWorkPermitInternship from './CalculateCoopWorkPermitInternship'
import CalculateLMIAExExtentionON from './CalculateLMIAExExtentionON'
import CalculateLMIAExExtentionPOE from './CalculateLMIAExExtentionPOE'
import CalculateLMIAPosExPOE from './CalculateLMIAPosExPOE'
import CalculateLMIAPosExtentionON from './CalculateLMIAPosExtentionON'
import Swal from 'sweetalert2'
import {FamilyTypes} from 'FormBuilder/components/FamilyModule/types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import './style.css'
import pnglogo from './download.png'
import {sendAlert} from 'modules/flows/sendEmail/core/_request'
import CalculateQCsponsorship from './CalculateQCsponsorship'
import CalculateQCsponshorshipFee from './CalculateQCsponshorshipFee'
import CalculatePermanentResidenceSponsorshipFeeParents from './CalculatePermanentResidenceSponsorshipFeeParents'
import CalculateQCAddMember from './CalculateQCAddMember'
import CalculateWorkPermitHolidayFee from './CalculateWorkPermitHolidayFee'
import InternationalExperienceCanadaWorkPermit from './InternationalExperienceCanadaWorkPermit'
import LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee from './LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee'
import CalculateSponsorDependantChild from './CalculateSponsorDependantChild'
import CalculateSpouseTwo from './CalculateSpouseTwo'
import CalculateDependantChildExEntryPhaseTwo from './CalculateDependantChildExEntryPhaseTwo'
import CalculateExEntryPhaseTwo from './CalculateExEntryPhaseTwo'
import CalculatePREcFee from './CalculatePREcFee'
import CalculatePReconomicSpouse from './CalculatePReconomicSpouse'
import CalculatePReconomicDepChild from './CalculatePReconomicDepChild'
import CalculateWorkPermitExemptONInsideCanada from './CalculateWorkPermitExemptONInsideCanada'
import CalculateTransitVisaFederalFee from './CalculateTransitVisaFederalFee'
import CalculateVisitorLessThanSixMonths from './CalculateVisitorLessThanSixMonths'
import CalculateSpousePN from './CalculateSpousePN'
import CalculateDependendPN from './CalculateDependendPN'
import CalculatePNProgram from './CalculatePNProgram'
import CalculatePN from './CalculatePN'
import Calculaterpcsq from './CalculateOtherPrFederal'
import CalculaterswpPAPHASEONE from './CalculaterswpPAPHASEONE'
import CalculateSpouseRSWPphaseone from './CalculateSpouseRSWPphaseone'
import CalculateCSQDependChild from './CalculateCSQDependChild'
import CalculateCSQpr from './CalculateCSQpr'
import CalculatExpiredPermit from './CalculateExpiredPermit'
import CalculatExpiredRestoration from './CalculateRestorationMoreThan90'
import CalculateDependentOtherPrFederal from './CalculateDependentOtherPrFederal'
import CalculateOtherPrFederal from './CalculateOtherPrFederal'
import CalculateSpouseOtherPrFederal from './CalculateSpouseOtherPrFederal'
import CalculateSpouseParnerFeeCSQ from './CalculateSpouseParnerFeeCSQ'
import CalculateSpousePartnerCSQ from './CalculateSpousePartnerCSQ'
import CalculateStudyLanguagePermitExtensionON from './CalculateStudyLanguagePermitExtensionON'
import moment from 'moment'
import CalculateEmploymentLetter from './CalculateEmploymentLetter'
import CalculateExpiredPermitMoreThan90Days from './CalculateExpiredPermitMoreThan90Days'
import CalculateATIP from './CalculateATIP'
import CalculatAtipFee from './CalculateATIPFee'
import CalculateOpenWPVulnerable from './CalculateOpenWPVulnerable'
import CalculateCitizenship from './CalculateCitizenship'
import CalculateCitizenshipAdult from './CalculateCitizenshipAdult'
import CalculateCitizenshipMinor from './CalculateCitizenshipMinor'
import CalculateDocumentAmendment from './CalculateDocumentAmendment'
import CalculateDocumentReplacement from './CalculateDocumentReplacement'
import CalculateDocumentReplacementGF from './CalculateDocumentReplacementGF'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable'

interface IProps {}
const EndModule: React.FC<IProps> = ({}) => {
  const [data, setData] = useState<Product[]>([])
  const dataNotParse = localStorage.getItem('products')

  if (dataNotParse !== null && data.length === 0) {
    setData((prevData) => JSON.parse(dataNotParse))
    /* 	setData(JSON.parse(dataNotParse)) */
  }
  const rootStore = useContext(RootStoreContext)
  const {prices, decreaseStep, clickbackbutton, step, setEstep} = rootStore.formBuilderStore
  const [showThanks, setShowThanks] = useState(false)
  const {setDiscount} = rootStore.familyStore
  const [matterFromResumeComponent, setMatterFromResumeComponent] = useState('')
  const [applicantInfoFromResumeComponent, setApplicantInfoMatterFromResumeComponent] = useState('')
  const {
    docusignData,
    getTotalTransactionFee,
    getLMIAPosExtentionON,
    getEmploymentletter,
    getTotalCanadaTaxFee,
    getDiscount,
    getAtip,
    getAtipFee,
    getExpiredPermit,
    getExpiredPermitMoreThan90,
    getLMIAExExtentionPOE,
    getLMIAPosExtentionPOE,
    getLMIAExExtentionON,
    getWorkPermitPositivePOE,
    getWorkPermitExemptPOE,
    getExpiredRestoration,
    getWorkPermitPositiveON,
    getWorkPermitExemptONDetails,
    getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee,
    getSponsorshipApplicationOut,
    getSpousePartnerExpressEntryExpressionOfInterestPhase1,
    getExpressEntryExpressionOfInterestPhase1,
    getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded,
    getCSQGovernmentApplicationFeeDependentChild,
    getCSQ,
    getOpenWorkPermitMainApplicant,
    getPGWPEx,
    getPGWPEON,
    getPGWPPOE,
    getPermanentResidenceSponsorshipFederalFee,
    getSponsorshipApplicationIn,
    getWorkPermitIncludesExtentionFederalFee,
    getOpenWorkPermitHolderFederalFee,
    getPartnerWorkingHolidayON,
    getCoop,
    getCalculateCAQWorkerGovermentFees,
    getCalculateCAQWorker,
    getCalculateCAQMinorGovermentFees,
    getCalculateCAQMinor,
    getCAQStudentGovernmentFee,
    getCalculateCAQExtentionStudent,
    getPartnerOpenWorkPermitPOE,
    getPartnerOpenWorkSpouse,
    getDependantVisitorPlusFive,
    getCalculatestudyPermitAcademicStreamDetails,
    getCalculatestudyPermitLangDetails,
    getCalculatestudyPermitFederalFeeDetails,
    getCalculateCAQStudent,
    getsetCalculatestudyPermitAcademicDetails,
    getEtaFederalFee,
    getSubmission,
    getEtaDetails,
    getStudyPermitExtentionON,
    getTravelDocumentRenovationStickerVisaON,
    getSpousePartnerOpenWorkPermitExtentionON,
    getDependantVisitorLessFive,
    getdependantStudyPermitExtentionLessFiveDetails,
    getDependantStudyPermitExtentionOverFiveDetails,
    getSuperVisaFederalFee,
    getSuperVisaDetails,
    getTransitVisaDetails,
    getBiometrics,
    getVisitorRecordFederal,
    getVisitorVisaDetails,
    getVisitorVisaDetailsLessThanSixMonths,
    getVisitorFederal,
    getVisitorRecord,
    getCSQApplicationFeeMainApplicant,
    getQCsponsorship,
    getQCsponsorshipFee,
    getResidenceSponsorshipFeeParents,
    getQCAddMember,
    getWorkingHolidayFee,
    getInternationalExperienceCanadaWorkPermit,
    getdependantChildIndependentSponsor,
    getSpouseExEntry,
    getdependantExEntry,
    getExpressEntryPhaseTwo,
    getPReconomicPA,
    getPReconomicSpouse,
    getCSQSpouseParner,
    getTransitVisaDetailsFee,
    getspouseRSWPphaseone,
    getCSQDependantChild,
    getCSQpr,
    getRSWPphaseone,
    getdepedantotherPRFederal,
    getotherPRFederal,
    getspouseotherPRFederal,
    getCSQSpouseParnerFee,
    getWorkPermitExemptONDetailsInsideCanada,
    getDependentPN,
    getPN,
    getPNPProgram,
    getSpousePN,
    getPReconomicDepChild,
    getStudyLanguagePermitExtentionON,
    getOpenWorkPermitVulnerable,
    getDocumentReplacement,
    getDocumentReplacementGF,
    getDocumentAmendment,
    getCitizenship,
    getCitizenshipAdult,
    getCitizenshipMinor,
    getoffertamplate,
  } = rootStore.familyStore
  const {
    principalUsed,
    familyMembers,
    resumeService,
    applicantsNames,
    getAnswers,
    setFamilyMember,
    lastStep,
  } = rootStore.familyStore
  useEffect(() => {}, [prices])
  useEffect(() => {}, [resumeService])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const date = `${docusignData().currentDate}`
  const email = `${principalUsed()?.email} `
  const matter = `${docusignData().resume}`
  const profesionalFees = `${parseFloat(docusignData().profesionalFee.toFixed(2)).toLocaleString(
    undefined,
    {minimumFractionDigits: 2}
  )}`
  const govermentFees = `${parseFloat(docusignData().govermentFee.toFixed(2)).toLocaleString(
    undefined,
    {minimumFractionDigits: 2}
  )}`
  const biometrics = `${parseFloat(docusignData().biometrics.toFixed(2)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })}`
  const miFiFees = `${parseFloat(docusignData().mifi.toFixed(2)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })}`
  const applicableTaxes = `${parseFloat(getTotalCanadaTaxFee().toFixed(2)).toLocaleString(
    undefined,
    {minimumFractionDigits: 2}
  )}`
  const transactionFee = `${parseFloat(getTotalTransactionFee().toFixed(2)).toLocaleString(
    undefined,
    {minimumFractionDigits: 2}
  )}`
  const totalCost = `${parseFloat(docusignData().total.toFixed(2)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })}`
  const pay = docusignData().total.toString()
  const clientAddress = `${principalUsed()?.address}  `
  const clientPhone = `${principalUsed()?.phoneNumber}  `
  const matchId = `${docusignData().matchId}`
  const url = `https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9eed55d7-4bb5-4629-ac55-5e980b478817&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_date=${date}&Client_name=${applicantsNames()}&Client_UserName=${
    principalUsed()?.name
  }&Client_matchId=${matchId}&Client_matter=${matter}&Client_professionalFees=${profesionalFees}&Client_governmentFees=${govermentFees}&Client_biometrics=${biometrics}&Client_mifiFees=${miFiFees}&Client_applicableTaxes=${applicableTaxes}&Client_transactionFee=${transactionFee}&Client_totalCost=${totalCost}&Client_clientName=${applicantsNames()}&Client_clientEmail=${email}&Client_clientPhone=${clientPhone}&Client_Email=${email}&Client_clientPaymentFixed=${pay}&ActivateOnly=1&Client_clientAddress=${clientAddress}`
  const IwantContract = () => {
    Swal.fire({
      title: '<span style="color: #13C296;"><u>¡Excelente!</u></span>',
      icon: 'success',
      html: `<p><b>Hemos enviado su contrato al correo:</b></p><br>
       <p>${familyMembers[0].email}</p><br>
       <p>El contrato es valido por un plazo de <b>30 días</b></p>
       <p>Tienes problemas? contáctanos a <b>retainer.agreement@immilandcanada.com</b></p>`,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow', // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }
  const serviceValue = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Honorarios Profesionales</span>',
      html: `
        <ol style="text-align: justify;">
          <li><b>1.</b> Revisión exhaustiva de documentos.</li>
          <li><b>2.</b> Estrategias para mejorar el perfil.</li>
          <li><b>3.</b> Diligenciamiento de formularios.</li>
          <li><b>4.</b> Redacción de cartas de motivos y presentación de fondos.</li>
          <li><b>5.</b> Envio de aplicación.</li>
          <li><b>6.</b> Monitoreo de la aplicación.</li>
          <li><b>7.</b> Notificación de comunicaciones.</li>
          <li><b>8.</b> De ser necesario, comunicación directa con el Gobierno de Canadá.</li>
        </ol>`,
      icon: 'info',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'btn-no-shadow', // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }

  const handleThanks = () => {
    const formattedProps: any[] = []
    console.log('pay', pay)
    fetch(url)
    const itemStryngifidos = JSON.parse(getAnswers()).map((item: {}) => {
      return JSON.stringify(item)
    })
    for (const prop of itemStryngifidos) {
      formattedProps.push(JSON.parse(prop))
    }
    const body = {
      props: itemStryngifidos,
      email: `${principalUsed()?.email}`,
      match_id: matchId,
      applicants: applicantInfoFromResumeComponent,
      formatted_props: formattedProps,
    }
    sendAnswersInfo(body)
    /*     sendAlert(familyMembers[0].name, familyMembers[0].lastname, familyMembers[0].email as string)
     */
    localStorage.removeItem('products')
    setShowThanks(true)
  }

  const back = () => {
    if (showThanks === true) {
      location.reload()
    } else {
      localStorage.removeItem(`birthday${familyMembers[0].id}`)
      familyMembers[0].name = ''
      familyMembers[0].lastname = ''
      familyMembers[0].birthday = new Date()
      familyMembers[0].birthdayFormatted = moment().format('YYYY-MM-DD')
      familyMembers[0].age = 0
      familyMembers[0].nationality = ''
      familyMembers[0].hasBiometrics = undefined
      familyMembers[0].biometricsForSelect = ''
      familyMembers[0].visaType = ''
      familyMembers[0].valid = undefined
      familyMembers[0].type = FamilyTypes.principal
      familyMembers[0].eta = ''
      familyMembers[0].etaCountry = undefined
      familyMembers[0].etaRejection = ''
      familyMembers[0].visaEU = ''
      familyMembers[0].etaImmiland = ''
      familyMembers[0].validEta = undefined
      familyMembers[0].canadianVisaRejection = ''
      familyMembers[0].email = ''
      familyMembers[0].address = ''
      familyMembers[0].phoneNumber = ''
      familyMembers[0].birthdayFormatted = ''
      familyMembers[0].monthDifference = 0
      familyMembers[0].ageForBiometrics = undefined
      familyMembers[0].ageIndependentChildren = undefined
      familyMembers[0].quebec = ''
      familyMembers[0].principalHasPathway = ''
      familyMembers[0].HasCaq = ''
      familyMembers[0].workPermit = undefined
      familyMembers[0].normalVisitorVisaLogic = undefined
      familyMembers[0].validPrincipalCaq = undefined
      familyMembers[0].validDependantStudent = undefined
      familyMembers[0].validAccompainedMinor = undefined
      familyMembers[0].visaTypeFromSurvey = ''
      familyMembers[0].hasLoa = ''
      familyMembers[0].validateCAQquestions = undefined
      familyMembers[0].typeWorkPermit = ''
      familyMembers[0].partnerHasWorkStudyPermit = ''
      familyMembers[0].partnerTypeOfPermit = ''
      familyMembers[0].borderPackage = undefined
      familyMembers[0].borderPackageForSelect = ''
      familyMembers[0].dliPGWP = ''
      familyMembers[0].aplicationType = ''
      familyMembers[0].hasLMIAAlready = ''
      familyMembers[0].ftaCountry = undefined
      familyMembers[0].caqChildren = undefined
      familyMembers[0].wantsBPValidationConyugue = undefined
      familyMembers[0].wantsTypeOfApplicationValidationConyugue = undefined
      familyMembers[0].employerName = ''
      familyMembers[0].employerEmail = ''
      familyMembers[0].companyName = ''
      familyMembers[0].typeOfProcedure = ''
      familyMembers[0].NOC = ''
      familyMembers[0].isPreviousClient = ''
      familyMembers[0].previousContractNumber = ''
      familyMembers[0].insideCanada = ''
      familyMembers[0].wantsVisitorExtension = ''
      familyMembers[0].wantsStudyExtension = ''
      familyMembers[0].studentDirectStream = ''
      familyMembers[0].optionOfExtension = ''
      familyMembers[0].currentStatus = ''
      familyMembers[0].ExpiredPermit = ''
      familyMembers[0].ExpiredPermit90 = ''
      familyMembers[0].ExpiredPermitRestoration = ''
      familyMembers[0].wantsToApplyToAnyExtension = ''
      familyMembers[0].typeOfExtensionBaseOnCurrentStatus = ''
      familyMembers[0].wantsWorkExtension = ''
      familyMembers[0].discountCode = ''
      familyMembers[0].wayToAnotherCountry = ''
      familyMembers[0].connectionCanadianAirport = ''
      familyMembers[0].transit48hours = ''
      familyMembers[0].isParent = ''
      familyMembers[0].typeOfVisaSuper = ''
      familyMembers[0].ageForVisitorDependant = undefined
      familyMembers[0].ageForCAQMinor = undefined
      familyMembers[0].typeOfVisitorVisa = ''
      familyMembers[0].hasInternetionalStop = undefined
      familyMembers[0].internationalStopSelect = ''
      familyMembers[0].hasInternetionalConextion = undefined
      familyMembers[0].internationalConextionSelect = ''
      familyMembers[0].stayFourtyeightHours = undefined
      familyMembers[0].stayFourtyeightHoursSelect = ''
      familyMembers[0].hasCanadianParents = undefined
      familyMembers[0].CanadianParentsForSelect = ''
      familyMembers[0].hasFinancialSupport = undefined
      familyMembers[0].FinancialSupportForSelect = ''
      familyMembers[0].hasPrivateInsurance = undefined
      familyMembers[0].PrivateInsuranceForSelect = ''
      familyMembers[0].isTransitVisa = undefined
      familyMembers[0].isSuperVisa = undefined
      familyMembers[0].inCanada = ''
      familyMembers[0].countryOfCitizenship = ''
      familyMembers[0].countryOfCurrentResidency = ''
      familyMembers[0].civilStatus = ''
      familyMembers[0].appliedBefore = ''
      familyMembers[0].confirmEmail = ''
      familyMembers[0].validBiometrics = ''
      familyMembers[0].wantsAddConyugue = 'no'
      familyMembers[0].wantsDependantChildren = 'no'
      familyMembers[0].howManyDependantChild = undefined
      familyMembers[0].wantAddTravelDocument = ''
      familyMembers[0].contractEnvelopeID = ''
      familyMembers[0].validContract = undefined
      familyMembers[0].dateOfBirth = ''
      familyMembers[0].wayChooseOnStudy = ''
      familyMembers[0].ApplyWithSpouse = 'no'
      familyMembers[0].haveEta = ''
      familyMembers[0].addEta = ''
      familyMembers[0].wantsBP = ''
      familyMembers[0].purpose = ''
      familyMembers[0].purposeVisit = ''
      familyMembers[0].passportCountry = ''
      familyMembers[0].province = ''
      familyMembers[0].canadianChildren = ''
      familyMembers[0].lessThanSixMonths = ''
      familyMembers[0].citizenship = ''
      familyMembers[0].typeOfApplication = ''
      familyMembers[0].acceptedLOAInstitution = ''
      familyMembers[0].typeOfStudyPermit = ''
      familyMembers[0].typeOfApplicationOfStudyPermit = ''
      familyMembers[0].languageRequired = ''
      familyMembers[0].applyingAlone = ''
      familyMembers[0].studyOption = ''
      familyMembers[0].complementaryService = ''
      familyMembers[0].scenery = ''
      familyMembers[0].lessThanSixCaq = ''
      familyMembers[0].typeOfWorkPermit = ''
      familyMembers[0].typeOfClosedWorkPermit = ''
      familyMembers[0].wantAddLmiaPositive = ''
      familyMembers[0].wantCaq = ''
      familyMembers[0].wantValidationLmiaExemptJobOffer = ''
      familyMembers[0].typeOfStatusConyuge = ''
      familyMembers[0].proofStatus = ''
      familyMembers[0].elegiblePGWP = ''
      familyMembers[0].kindOfTeer = ''
      familyMembers[0].labourMarketImpactAssessment = ''
      familyMembers[0].migratoryStatus = ''
      familyMembers[0].wantsTemporaryResidenceService = ''
      familyMembers[0].validacademicRecord = ''
      familyMembers[0].validcoopLetter = ''
      familyMembers[0].casePGWP = ''
      familyMembers[0].sponsorship = ''
      familyMembers[0].programAplication = ''
      familyMembers[0].programAplicationExplain = ''
      familyMembers[0].moreThan18 = ''
      familyMembers[0].isRecident = ''
      familyMembers[0].isDisability = ''
      familyMembers[0].addOpenWork = ''
      familyMembers[0].addCSQ = ''
      familyMembers[0].addCSQChildren = ''
      familyMembers[0].typeOfClass = ''
      familyMembers[0].wantwp = ''
      familyMembers[0].phase = ''
      familyMembers[0].phaseexpress = ''
      familyMembers[0].pnpprogram = ''
      familyMembers[0].phasepnp = ''
      familyMembers[0].expresion = undefined
      familyMembers[0].csq = undefined
      familyMembers[0].rpcsq = undefined
      familyMembers[0].csqPEQ = undefined
      familyMembers[0].rpcsqPEQ = undefined
      familyMembers[0].pn = undefined
      familyMembers[0].prendorsement = undefined
      familyMembers[0].employletter = undefined
      familyMembers[0].ResponsableValidacionLMIA = undefined

      setDiscount({
        id: '',
        productContratationId: '',
        description: '',
        totalFee: 0,
        name: 'Codigo de descuento',
        quantity: 0,
        fee: 0,
      })

      const filteredFamilyMembers = familyMembers.filter(
        (member) => member.type !== FamilyTypes.conyuge && member.type !== FamilyTypes.children
      )
      setFamilyMember(filteredFamilyMembers)
      setEstep(1)
      setIsCircleContainerVisible(false)
    }
  }

  const [isCircleContainerVisible, setIsCircleContainerVisible] = useState(true)
  const [memberVisibility, setMemberVisibility] = useState<Record<string, boolean>>({})

  // Función para alternar la visibilidad de un miembro
  const toggleMemberVisibility = (memberId: any) => {
    setMemberVisibility((prevState) => ({
      ...prevState,
      [memberId]: !prevState[memberId],
    }))
  }

  useEffect(() => {}, [data])

  let indexChildren = 1

  const todaysdate = () => {
    const fechaHoy = new Date()
    const fechaFormateada = `${fechaHoy.getFullYear()}-${(fechaHoy.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${fechaHoy.getDate().toString().padStart(2, '0')}`
    return fechaFormateada
  }

  const captureScreen = () => {
    const elementToCapture = document.getElementById('element-to-capture') // Reemplaza 'element-to-capture' con el ID del elemento que deseas capturar
    if (elementToCapture) {
      html2canvas(elementToCapture).then((canvas: any) => {
        // Crea un enlace (link) para descargar la imagen
        const downloadLink = document.createElement('a')
        downloadLink.href = canvas.toDataURL('image/png')
        const date = todaysdate()
        downloadLink.download = `contratacionimmiland${date}.png` // Nombre del archivo para descargar
        // Simula un clic en el enlace para descargar la imagen
        downloadLink.click()
      })
    } else {
      console.error('Element not found or is null.')
    }
  }

  const captureScreenshot = () => {
    const today = new Date()
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`

    // Create the file name
    const fileName = `Cotizacion ImmilandLaw - ${formattedDate}.png`

    console.log('Capturing screenshot 1')
    const element = document.getElementById('element-to-capture')
    console.log('Capturing screenshot 2')

    if (element) {
      console.log('Capturing screenshot 3')

      // Get the actual dimensions of the element
      const rect = element.getBoundingClientRect()
      const originalStyle = element.style.cssText

      // Set the element dimensions for html2canvas
      element.style.width = `${rect.width}px`
      element.style.height = `${rect.height}px`

      html2canvas(element, {useCORS: true, width: rect.width, height: rect.height})
        .then((canvas) => {
          console.log('Capturing screenshot 4')

          // Revert to original style after the screenshot
          element.style.cssText = originalStyle

          // Create a download link for the screenshot
          const link = document.createElement('a')
          link.download = fileName
          link.href = canvas.toDataURL('image/png')
          link.click()
        })
        .catch((error) => {
          console.error('Error capturing screenshot:', error)
        })
    }
  }

  const functions = [
    getCSQApplicationFeeMainApplicant,
    getCSQGovernmentApplicationFeeDependentChild,
    getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded,
    getBiometrics,
    getEtaDetails,
    getStudyPermitExtentionON,
    getTravelDocumentRenovationStickerVisaON,
    getSpousePartnerOpenWorkPermitExtentionON,
    getDependantVisitorLessFive,
    getdependantStudyPermitExtentionLessFiveDetails,
    getDependantStudyPermitExtentionOverFiveDetails,
    getSuperVisaFederalFee,
    getSuperVisaDetails,
    getTransitVisaDetails,
    getVisitorRecordFederal,
    getVisitorVisaDetails,
    getVisitorVisaDetailsLessThanSixMonths,
    getVisitorFederal,
    getVisitorRecord,
    getQCsponsorship,
    getQCsponsorshipFee,
    getResidenceSponsorshipFeeParents,
    getQCAddMember,
    getWorkingHolidayFee,
    getInternationalExperienceCanadaWorkPermit,
    getdependantChildIndependentSponsor,
    getSpouseExEntry,
    getdependantExEntry,
    getExpressEntryPhaseTwo,
    getPReconomicPA,
    getPReconomicSpouse,
    getCSQSpouseParner,
    getTransitVisaDetailsFee,
    getspouseRSWPphaseone,
    getCSQDependantChild,
    getCSQpr,
    
    getRSWPphaseone,
    getdepedantotherPRFederal,
    getotherPRFederal,
    getspouseotherPRFederal,
    getCSQSpouseParnerFee,
    getWorkPermitExemptONDetailsInsideCanada,
    getDependentPN,
    getPN,
    getPNPProgram,
    getSpousePN,
    getPReconomicDepChild,
    getStudyLanguagePermitExtentionON,
    getOpenWorkPermitVulnerable,
    getDocumentReplacement,
    getDocumentReplacementGF,
    getDocumentAmendment,
    getCitizenship,
    getCitizenshipAdult,
    getCitizenshipMinor,
    getoffertamplate,
    getAtipFee,
    getExpiredPermitMoreThan90,
    getLMIAPosExtentionON,
    getEmploymentletter,
    getDiscount,
    getAtip,
    getExpiredPermit,
    getLMIAExExtentionPOE,
    getLMIAPosExtentionPOE,
    getLMIAExExtentionON,
    getWorkPermitPositivePOE,
    getWorkPermitExemptPOE,
    getExpiredRestoration,
    getWorkPermitPositiveON,
    getWorkPermitExemptONDetails,
    getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee,
    getSponsorshipApplicationOut,
    getSpousePartnerExpressEntryExpressionOfInterestPhase1,
    getExpressEntryExpressionOfInterestPhase1,
    getCSQ,
    getOpenWorkPermitMainApplicant,
    getPGWPEx,
    getPGWPEON,
    getPGWPPOE,
    getSponsorshipApplicationIn,
    getWorkPermitIncludesExtentionFederalFee,
    getOpenWorkPermitHolderFederalFee,
    getPartnerWorkingHolidayON,
    getCoop,
    getCalculateCAQWorkerGovermentFees,
    getCalculateCAQWorker,
    getCalculateCAQMinorGovermentFees,
    getCalculateCAQMinor,
    getCAQStudentGovernmentFee,
    getCalculateCAQExtentionStudent,
    getPartnerOpenWorkPermitPOE,
    getPartnerOpenWorkSpouse,
    getDependantVisitorPlusFive,
    getCalculatestudyPermitAcademicStreamDetails,
    getCalculatestudyPermitLangDetails,
    getCalculatestudyPermitFederalFeeDetails,
    getCalculateCAQStudent,
    getsetCalculatestudyPermitAcademicDetails,
    getEtaFederalFee,
    getSubmission,
    // Add other functions here
];

const storedObjects: any[] = []; // Array to store the objects

function printFunctionsWithQuantityGreaterThanZero(
  functions: (() => { quantity?: number; [key: string]: any } | number)[]
) {
  console.log("Functions with quantity greater than zero:");
  for (const func of functions) {
    const result = func();
    if (typeof result === "number") {
      if (result > 0) {
        console.log(func.name, result);
        storedObjects.push({ name: func.name, quantity: result });
      }
    } else if (typeof result === "object" && result !== null && "quantity" in result) {
      if (result.quantity !== undefined && result.quantity > 0) {
        console.log(func.name, result);
        storedObjects.push(result); // Store the entire object
      }
    }
  }
}


/* const generatePDF = () => {

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let y = 20; // Starting Y-axis for content

  // Load and add the PNG logo at the top
  const logoUrl = '/icons/immiland_logo_invoice.png';
  const img = new Image();
  img.src = logoUrl;

  img.onload = () => {
      const imgWidth = 60;
      const imgHeight = 20;
      const imgX = pageWidth - imgWidth - 10;

      doc.addImage(img, 'PNG', imgX, 10, imgWidth, imgHeight);
      y += imgHeight;

      // Add title
      doc.setFontSize(18);
      doc.text('Immiland High Level Estimate', 20, y);
      y += 10;

      // Prepare table data
      const tableBody = Object.keys(products)
          .filter((productId) => productQuantities[productId] > 0)
          .map((productId) => {
              const product = products?.find((p) => p.id === productId);
              const quantity = productQuantities[productId];
              const unitPrice = product?.price || 0;
              const total = unitPrice * quantity;
      
              return [
                  product?.name || 'Unknown Product', 
                  `CAD${formatter.format(unitPrice)}`, 
                  quantity || 0, 
                  `CAD${formatter.format(total)}`
              ];
          });

      // Generate table with borders
      autoTable(doc, {
          startY: y,
          head: [['Service', 'Unit Price', 'Quantity', 'Total']],
          body: tableBody,
          styles: {
              fontSize: 10,
              cellPadding: 4,
              valign: 'middle',
          },
          headStyles: {
              fillColor: [0, 0, 0], // Black header background
              textColor: [255, 255, 255], // White text
              fontStyle: 'bold',
          },
          alternateRowStyles: {
              fillColor: [245, 245, 245], // Light gray background for alternate rows
          },
          margin: { top: 10 },
      });

      y = (doc as any).autoTable.previous.finalY + 10; // Adjust Y position after table

      // Add total fee calculations
      const discountValue = !discountApplied && taxProducts.ataxes.length > 0 ? pf * 0.1 : discount;
      doc.setFontSize(11);
      doc.text(`Total Applicable Taxes: CAD ${formatter.format(taxProducts.ataxes.length > 0 ? ((pf - discountValue) * 0.13) : 0)}`, 20, y);
      y += 9;
      doc.text(`Transaction Fee: CAD ${formatter.format(((totalFee - discountValue + (taxProducts.ataxes.length > 0 ? ((pf - discountValue) * 0.13) : 0)) * 0.04))}`, 20, y);
      y += 9;

      if (discountApplied) {
          doc.text(`Discount Applied "${discountCode}": CAD ${formatter.format(discount)}`, 20, y);
          y += 9;
      } else if (taxProducts.ataxes.length > 0) {
          doc.text(`Discount Applied 10% OFF Professional Fees (Inside Canada): CAD ${formatter.format(discountValue)}`, 20, y);
          y += 9;
      }

      const totalFeeWithTaxes = (totalFee - discountValue + (taxProducts.ataxes.length > 0 ? ((pf - discountValue) * 0.13) : 0)) * 1.04;
      doc.text(`Total Fee estimate: CAD ${formatter.format(totalFeeWithTaxes)}`, 20, y);
      y += 20;

      // Add footer
      doc.setFillColor(0, 0, 0);
      doc.rect(0, pageHeight - 20, pageWidth, 20, 'F'); // Footer black bar
      doc.setTextColor(255, 255, 255);
      doc.text('www.immilandlaw.com', pageWidth / 2, pageHeight - 10, { align: 'center' });

      // Add disclaimers page
    doc.addPage();
    y = 50; // Reset Y for the new page


      const disclaimers = [
          {
              title: 'Español:',
              text: `Aviso Legal Importante
Por favor, tenga en cuenta lo siguiente:
1. Propuesta Informativa: Esta cotización no constituye una propuesta formal ni una oferta definitiva de nuestros servicios. Está destinada únicamente a proporcionar una visión general de los costos aproximados asociados a nuestros servicios.
2. Sujeto a Cambios: Los costos y servicios indicados pueden variar debido a factores como cambios en los precios, su situación personal, o el tipo de visa que desee solicitar.
3. Relación Profesional: Esta cotización no establece una relación abogado-cliente. Dicha relación solo se considerará válida una vez que se firme un contrato formal y se realice el pago correspondiente por los servicios.
4. Costos Adicionales: Los costos estimados no incluyen tarifas gubernamentales, impuestos, o gastos adicionales que puedan surgir durante el proceso migratorio.
5. Exclusión de Garantías: No garantizamos un resultado específico (como la aprobación de una visa), ya que esto depende de las autoridades gubernamentales y otros factores fuera de nuestro control.
El precio final y formal de los servicios será proporcionado en el siguiente enlace: https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402`,
          },
          {
              title: 'English:',
              text: `Important Legal Notice
Please note the following:
1. Informative Proposal: This quotation does not constitute a formal proposal or definitive offer of our services. It is intended solely to provide a general overview of the approximate costs associated with our services.
2. Subject to Change: The indicated costs and services may vary due to factors such as price changes, your personal circumstances, or the type of visa you wish to apply for.
3. Professional Relationship: This quotation does not establish an attorney-client relationship. Such a relationship will only be valid once a formal contract is signed and the corresponding payment for services is made.
4. Additional Costs: The estimated costs do not include government fees, taxes, or additional expenses that may arise during the immigration process.
5. Exclusion of Guarantees: We do not guarantee a specific outcome (such as visa approval), as this depends on governmental authorities and other factors beyond our control.
The final and formal price of services will be provided at: https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402`,
          }, 
          {
            title: 'Français:',
            text: `Avis Légal Important
  Veuillez noter les éléments suivants :
  1. Proposition Informative : Ce devis ne constitue pas une proposition formelle ni une offre définitive de nos services. Il est destiné uniquement à fournir une vue d'ensemble des coûts approximatifs liés à nos services.
  2. Sous Réserve de Modifications : Les coûts et services indiqués peuvent varier en fonction de changements de prix, de votre situation personnelle ou du type de visa que vous souhaitez demander.
  3. Relation Professionnelle : Ce devis ne crée pas de relation avocat-client. Une telle relation ne sera valable que lorsqu'un contrat formel sera signé et que le paiement correspondant des services sera effectué.
  4. Coûts Supplémentaires : Les coûts estimés n'incluent pas les frais gouvernementaux, taxes ou dépenses supplémentaires pouvant survenir durant le processus d'immigration.
  5. Exclusion de Garanties : Nous ne garantissons pas un résultat spécifique (comme l'approbation d'un visa), car cela dépend des autorités gouvernementales et d'autres facteurs hors de notre contrôle.
  Le prix final et formel des services sera fourni une fois que vous aurez complété votre devis via le lien suivant : https://flow.immiland.app/flow/4e52566e-eefe-41e4-8123-5d51910c9402`
          }
      ];

      disclaimers.forEach((disclaimer) => {
        // Add header
        doc.addImage(img, 'PNG', imgX, 10, imgWidth, imgHeight);
  
        // Add title
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(disclaimer.title, 10, y);
        y += 10;
  
        // Add disclaimer text
        doc.setFontSize(6);
        const disclaimerText = doc.splitTextToSize(disclaimer.text, pageWidth - 30); // Adjust text width
        disclaimerText.forEach((line: string) => {
          if (y + 10 > pageHeight - 30) {
            doc.setFillColor(0, 0, 0);
            doc.rect(0, pageHeight - 20, pageWidth, 20, 'F'); // Footer black bar
            doc.setTextColor(255, 255, 255);
            doc.text('www.immilandlaw.com', pageWidth / 2, pageHeight - 10, { align: 'center' });
        
            doc.addPage();
            y = 30; // Reset Y for the new page
            doc.addImage(img, 'PNG', imgX, 10, imgWidth, imgHeight); // Add header again
          }
          doc.text(line, 10, y); // Reduced indentation from 20 to 10
          y += 4;
        });
  
        y += 10; // Add space after each disclaimer
      });
  
      // Add footer to the last page
      doc.setFillColor(0, 0, 0);
      doc.setFontSize(12);
      doc.rect(0, pageHeight - 20, pageWidth, 20, 'F'); // Footer black bar
      doc.setTextColor(255, 255, 255);
      doc.text('www.immilandlaw.com', pageWidth / 2, pageHeight - 10, { align: 'center' });
  
      // Save the PDF
      const now = new Date();
      const formattedDate = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];
      doc.save(`Immiland_Cotizacion_${formattedDate}.pdf`);
    };
  
    img.onerror = () => {
      toast.error('Error loading logo image.', {
        position: 'top-center',
        autoClose: 3000,
      });
    };
  }; */

  const generatePDF = () => {
    storedObjects.length = 0; // Clear previous data to prevent duplication
    printFunctionsWithQuantityGreaterThanZero(functions);
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let y = 20;
  
    // Load and add the PNG logo at the top
    const logoUrl = "/icons/immiland_logo_invoice.png";
    const img = new Image();
    img.src = logoUrl;
  
    img.onload = () => {
      doc.addImage(img, "PNG", pageWidth - 70, 10, 60, 20);
      y += 15;
  
      // Add Title
      doc.setFontSize(18);
      doc.text("Immiland High Level Estimate", 20, y);
      y += 5;
  
      // **Filter Table Data: Exclude Discount & Ensure Only Named Products Appear**
      const excludedProducts = ["Codigo de descuento"]; // Exclude discount-related products
      const filteredProducts = storedObjects.filter(
        (product) => product.name && !excludedProducts.includes(product.name)
      );
  
      // Calculate total fees from valid products (excluding discounts, taxes, and transaction fees)
      const totalProductFees = filteredProducts.reduce(
        (sum, product) => sum + (product.totalFee || 0),
        0
      );
  
      // Prepare table data for valid products
      const tableBody = filteredProducts.map((product) => [
        product.name || "Unknown Product",
        `CAD$${product.totalFee || 0}`,
        product.quantity || 0,
        `CAD$${(product.totalFee || 0)}`,
      ]);
  
      // Generate Table
      autoTable(doc, {
        startY: y,
        head: [["Service", "Unit Price", "Quantity", "Total"]],
        body: tableBody,
        styles: {
          fontSize: 10,
          cellPadding: 4,
          valign: "middle",
        },
        headStyles: {
          fillColor: [0, 0, 0],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245],
        },
        margin: { top: 10 },
      });
  
      // Adjust Y position after table
      y = (doc as any).autoTable.previous.finalY + 4;
  
      // --- Fetch Additional Fees ---
      const discountValue = getDiscount()?.totalFee * getDiscount()?.quantity || 0;
      const totalApplicableTaxes = getTotalCanadaTaxFee() || 0;
      const transactionFee = getTotalTransactionFee() || 0;
  
      // --- Compute Total Fee with Taxes ---
      const totalFeeWithTaxes = totalProductFees + totalApplicableTaxes + transactionFee - discountValue;
  
      // --- Add Additional Fee Calculations to PDF (But not in table) ---
      doc.setFontSize(10);
  
      if (totalApplicableTaxes > 0) {
        doc.text(`Total Applicable Taxes: CAD ${formatter.format(totalApplicableTaxes)}`, 20, y);
        y += 4;
      }
  
      if (transactionFee > 0) {
        doc.text(`Transaction Fee: CAD ${formatter.format(transactionFee)}`, 20, y);
        y += 4;
      }
  
      if (discountValue > 0) {
        doc.text(`Discount Applied: CAD ${formatter.format(discountValue)}`, 20, y);
        y += 4;
      }
  
      doc.text(`Total Fee estimate: CAD ${formatter.format(totalFeeWithTaxes)}`, 20, y);
      y += 20;
      doc.setFontSize(11);
  
      // Add Footer
      doc.setFillColor(0, 0, 0);
      doc.rect(0, pageHeight - 20, pageWidth, 20, "F"); // Footer black bar
      doc.setTextColor(255, 255, 255);
      doc.text("www.immilandlaw.com", pageWidth / 2, pageHeight - 10, { align: "center" });
  
      // --- Add New Page for Family Member Details ---
      if (familyMembers.length > 0) {
        doc.addPage();
        y = 20; // Reset Y for the new page
  
        // Add header image again
        doc.addImage(img, "PNG", pageWidth - 70, 10, 60, 20);
        y += 15;
  
        // Add section title
        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);
        doc.text("Form Answers:", 20, y);
        y += 10;
  
        // Extract non-empty values from familyMembers
        familyMembers.forEach((member, index) => {
          doc.setFontSize(12);
          doc.text(`Info ${member.type}`, 20, y);
          y += 8;
  
          const details = {
            "Full Name": `${member.name} ${member.lastname}`,
            "Age": member.age,
            "Birthday": member.birthdayFormatted,
            "Nationality": member.nationality,
            "Current Residency": member.countryOfCurrentResidency,
            "Civil Status": member.civilStatus,
            "Visa Type": member.visaType,
            "Valid Biometrics": member.validBiometrics,
            "Has Canadian Visa Rejection": member.canadianVisaRejection,
            "Discount Code": member.discountCode
          };
  
          Object.entries(details).forEach(([key, value]) => {
            if (value && value !== "") {
              doc.setFontSize(10);
              doc.text(`${key}: ${value}`, 20, y);
              y += 6;
            }
          });
  
          y += 10; // Space between family members
  
          if (y > pageHeight - 30) {
            doc.addPage();
            y = 20; // Reset for the new page
            doc.addImage(img, "PNG", pageWidth - 70, 10, 60, 20); // Add header again
            y += 30;
          }
        });
  
        // Add Footer to new page
        doc.setFillColor(0, 0, 0);
        doc.rect(0, pageHeight - 20, pageWidth, 20, "F"); // Footer black bar
        doc.setTextColor(255, 255, 255);
        doc.text("www.immilandlaw.com", pageWidth / 2, pageHeight - 10, { align: "center" });
      }
  
      // Save PDF
      doc.save(`Immiland_Cotizacion_${new Date().toISOString().split("T")[0]}.pdf`);
    };
  
    img.onerror = () => {
      console.error("Error loading logo image.");
    };
  };
  
  
  
  
  
  

  const goBack = () => {
    if (showThanks === true) {
      location.reload()
    } else {
      localStorage.removeItem(`birthday${familyMembers[0].id}`)
      familyMembers[0].name = ''
      familyMembers[0].lastname = ''
      familyMembers[0].birthday = new Date()
      familyMembers[0].birthdayFormatted = moment().format('YYYY-MM-DD')
      familyMembers[0].age = 0
      familyMembers[0].nationality = ''
      familyMembers[0].hasBiometrics = undefined
      familyMembers[0].biometricsForSelect = ''
      familyMembers[0].visaType = ''
      familyMembers[0].valid = undefined
      familyMembers[0].type = FamilyTypes.principal
      familyMembers[0].eta = ''
      familyMembers[0].etaCountry = undefined
      familyMembers[0].etaRejection = ''
      familyMembers[0].visaEU = ''
      familyMembers[0].etaImmiland = ''
      familyMembers[0].validEta = undefined
      familyMembers[0].canadianVisaRejection = ''
      familyMembers[0].email = ''
      familyMembers[0].address = ''
      familyMembers[0].phoneNumber = ''
      familyMembers[0].birthdayFormatted = ''
      familyMembers[0].monthDifference = 0
      familyMembers[0].ageForBiometrics = undefined
      familyMembers[0].ageIndependentChildren = undefined
      familyMembers[0].quebec = ''
      familyMembers[0].principalHasPathway = ''
      familyMembers[0].HasCaq = ''
      familyMembers[0].workPermit = undefined
      familyMembers[0].normalVisitorVisaLogic = undefined
      familyMembers[0].validPrincipalCaq = undefined
      familyMembers[0].validDependantStudent = undefined
      familyMembers[0].validAccompainedMinor = undefined
      familyMembers[0].visaTypeFromSurvey = ''
      familyMembers[0].hasLoa = ''
      familyMembers[0].validateCAQquestions = undefined
      familyMembers[0].typeWorkPermit = ''
      familyMembers[0].partnerHasWorkStudyPermit = ''
      familyMembers[0].partnerTypeOfPermit = ''
      familyMembers[0].borderPackage = undefined
      familyMembers[0].borderPackageForSelect = ''
      familyMembers[0].dliPGWP = ''
      familyMembers[0].aplicationType = ''
      familyMembers[0].hasLMIAAlready = ''
      familyMembers[0].ftaCountry = undefined
      familyMembers[0].caqChildren = undefined
      familyMembers[0].wantsBPValidationConyugue = undefined
      familyMembers[0].wantsTypeOfApplicationValidationConyugue = undefined
      familyMembers[0].employerName = ''
      familyMembers[0].employerEmail = ''
      familyMembers[0].companyName = ''
      familyMembers[0].typeOfProcedure = ''
      familyMembers[0].NOC = ''
      familyMembers[0].isPreviousClient = ''
      familyMembers[0].previousContractNumber = ''
      familyMembers[0].insideCanada = ''
      familyMembers[0].wantsVisitorExtension = ''
      familyMembers[0].wantsStudyExtension = ''
      familyMembers[0].studentDirectStream = ''
      familyMembers[0].optionOfExtension = ''
      familyMembers[0].currentStatus = ''
      familyMembers[0].ExpiredPermit = ''
      familyMembers[0].ExpiredPermit90 = ''
      familyMembers[0].ExpiredPermitRestoration = ''
      familyMembers[0].wantsToApplyToAnyExtension = ''
      familyMembers[0].typeOfExtensionBaseOnCurrentStatus = ''
      familyMembers[0].wantsWorkExtension = ''
      familyMembers[0].discountCode = ''
      familyMembers[0].wayToAnotherCountry = ''
      familyMembers[0].connectionCanadianAirport = ''
      familyMembers[0].transit48hours = ''
      familyMembers[0].isParent = ''
      familyMembers[0].typeOfVisaSuper = ''
      familyMembers[0].ageForVisitorDependant = undefined
      familyMembers[0].ageForCAQMinor = undefined
      familyMembers[0].typeOfVisitorVisa = ''
      familyMembers[0].hasInternetionalStop = undefined
      familyMembers[0].internationalStopSelect = ''
      familyMembers[0].hasInternetionalConextion = undefined
      familyMembers[0].internationalConextionSelect = ''
      familyMembers[0].stayFourtyeightHours = undefined
      familyMembers[0].stayFourtyeightHoursSelect = ''
      familyMembers[0].hasCanadianParents = undefined
      familyMembers[0].CanadianParentsForSelect = ''
      familyMembers[0].hasFinancialSupport = undefined
      familyMembers[0].FinancialSupportForSelect = ''
      familyMembers[0].hasPrivateInsurance = undefined
      familyMembers[0].PrivateInsuranceForSelect = ''
      familyMembers[0].isTransitVisa = undefined
      familyMembers[0].isSuperVisa = undefined
      familyMembers[0].inCanada = ''
      familyMembers[0].countryOfCitizenship = ''
      familyMembers[0].countryOfCurrentResidency = ''
      familyMembers[0].civilStatus = ''
      familyMembers[0].appliedBefore = ''
      familyMembers[0].confirmEmail = ''
      familyMembers[0].validBiometrics = ''
      familyMembers[0].wantsAddConyugue = 'no'
      familyMembers[0].wantsDependantChildren = 'no'
      familyMembers[0].howManyDependantChild = undefined
      familyMembers[0].wantAddTravelDocument = ''
      familyMembers[0].contractEnvelopeID = ''
      familyMembers[0].validContract = undefined
      familyMembers[0].dateOfBirth = ''
      familyMembers[0].wayChooseOnStudy = ''
      familyMembers[0].ApplyWithSpouse = 'no'
      familyMembers[0].haveEta = ''
      familyMembers[0].addEta = ''
      familyMembers[0].wantsBP = ''
      familyMembers[0].purpose = ''
      familyMembers[0].purposeVisit = ''
      familyMembers[0].passportCountry = ''
      familyMembers[0].province = ''
      familyMembers[0].canadianChildren = ''
      familyMembers[0].lessThanSixMonths = ''
      familyMembers[0].citizenship = ''
      familyMembers[0].typeOfApplication = ''
      familyMembers[0].acceptedLOAInstitution = ''
      familyMembers[0].typeOfStudyPermit = ''
      familyMembers[0].typeOfApplicationOfStudyPermit = ''
      familyMembers[0].languageRequired = ''
      familyMembers[0].applyingAlone = ''
      familyMembers[0].studyOption = ''
      familyMembers[0].complementaryService = ''
      familyMembers[0].scenery = ''
      familyMembers[0].lessThanSixCaq = ''
      familyMembers[0].typeOfWorkPermit = ''
      familyMembers[0].typeOfClosedWorkPermit = ''
      familyMembers[0].wantAddLmiaPositive = ''
      familyMembers[0].wantCaq = ''
      familyMembers[0].wantValidationLmiaExemptJobOffer = ''
      familyMembers[0].typeOfStatusConyuge = ''
      familyMembers[0].proofStatus = ''
      familyMembers[0].elegiblePGWP = ''
      familyMembers[0].kindOfTeer = ''
      familyMembers[0].labourMarketImpactAssessment = ''
      familyMembers[0].migratoryStatus = ''
      familyMembers[0].wantsTemporaryResidenceService = ''
      familyMembers[0].validacademicRecord = ''
      familyMembers[0].validcoopLetter = ''
      familyMembers[0].casePGWP = ''
      familyMembers[0].sponsorship = ''
      familyMembers[0].programAplication = ''
      familyMembers[0].programAplicationExplain = ''
      familyMembers[0].moreThan18 = ''
      familyMembers[0].isRecident = ''
      familyMembers[0].isDisability = ''
      familyMembers[0].addOpenWork = ''
      familyMembers[0].addCSQ = ''
      familyMembers[0].addCSQChildren = ''
      familyMembers[0].typeOfClass = ''
      familyMembers[0].wantwp = ''
      familyMembers[0].phase = ''
      familyMembers[0].phaseexpress = ''
      familyMembers[0].pnpprogram = ''
      familyMembers[0].phasepnp = ''
      familyMembers[0].expresion = undefined
      familyMembers[0].csq = undefined
      familyMembers[0].rpcsq = undefined
      familyMembers[0].csqPEQ = undefined
      familyMembers[0].rpcsqPEQ = undefined
      familyMembers[0].pn = undefined
      familyMembers[0].prendorsement = undefined
      familyMembers[0].employletter = undefined
      familyMembers[0].ResponsableValidacionLMIA = undefined

      setDiscount({
        id: '',
        productContratationId: '',
        description: '',
        totalFee: 0,
        name: 'Codigo de descuento',
        quantity: 0,
        fee: 0,
      })

      const filteredFamilyMembers = familyMembers.filter(
        (member) => member.type !== FamilyTypes.conyuge && member.type !== FamilyTypes.children
      )
      setFamilyMember(filteredFamilyMembers)
      setEstep(1)
      setIsCircleContainerVisible(false)
    }
    location.reload()
  }

  const circleStyle = {
    width: '20px',
    height: '20px',
    backgroundColor: '#0B76B7',
    color: 'white',
    borderRadius: '50%',
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '7px',
    display: 'inline-block',
    lineHeight: '25px',
    paddingLeft: '8px',
    paddingBottom: '10px',
    marginBottom: '5px',
  }

  if (data.length !== 0) {
    return (
      <div>
        <div
          className='flex flex-col'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '30px',
          }}
        >
          <div className='step-two-container-cont'>
            <div className='step-two-container' style={{backgroundColor: 'white'}}>
              <div
                className='stepper-container'
                style={{display: 'flex', justifyContent: 'space-between', width: '80%'}}
              >
                {/* Step 1 */}
                <div className='step'>
                  <div className='circle'>1</div>
                  <p className='step-label'>PROPÓSITO</p>
                </div>

                {/* Step 2 */}
                <div className='step'>
                  <div className='circle'>2</div>
                  <p className='step-label'>DATOS MIGRATORIOS</p>
                </div>

                {/* Step 3 */}
                <div className='step selected'>
                  <div className='circle'>3</div>
                  <p className='step-label'>COTIZACIÓN</p>
                </div>
              </div>

              <div className='progress-line'>
                <div className='progress-line-completed'>
                  <div className='dot completed-dot'></div>
                  <div className='checkmark-icon'>
                    <div className='checkmark'></div>
                  </div>
                </div>
                <div className='progress-line-completed2'>
                  <div className='checkmark-icon'>
                    <div className='checkmark'></div>
                  </div>
                </div>
                <div className='progress-line-completed3'>
                  <div className='dot completed-dot1'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='px-4' style={{fontFamily: 'CocogoosePro, sans-serif'}}>
          <>
            <div id='element-to-capture'>
              <div className='container-cotization-row'>
                <div style={{width: '100%'}}>
                  <h1
                    style={{
                      color: '#0B76B7',
                      fontSize: '1rem',
                      marginBottom: '1rem',
                      marginLeft: '1rem',
                      marginTop: '120px',
                    }}
                  >
                    Datos del solicitante
                  </h1>
                  <div className='answers-container'>
                    {familyMembers.map((member: any) => (
                      <div className='phone-selector' key={member.id}>
                        {member.type !== FamilyTypes.principal ? (
                          <div className='btn-aplicant-inf'>
                            {member.type}{' '}
                            {member.type === FamilyTypes.children && `#${indexChildren++}`}
                            <button
                              style={{
                                border: '1px solid',
                                padding: '3px',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                              }}
                              onClick={() => toggleMemberVisibility(member.id)}
                            >
                              <FontAwesomeIcon
                                icon={memberVisibility[member.id] ? faChevronUp : faChevronDown}
                              />
                            </button>
                          </div>
                        ) : (
                          <div>
                            {member.answers &&
                              member.answers.map((answer: any, index: any) => (
                                <div className='container-all-questions' key={answer.id}>
                                  <ul
                                    className='cotization-form'
                                    style={{display: 'flex', flexDirection: 'column'}}
                                  >
                                    {Object.entries(answer).map(
                                      ([key, value]) =>
                                        value !== null &&
                                        value !== undefined &&
                                        (typeof value !== 'string' || value.trim() !== '') && (
                                          <li key={key}>
                                            {key === 'Proposito visita' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>
                                                  Propósito de la visita:
                                                </p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Nombre' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Apellidos' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Aplico antes?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Ha tenido un rechazo previo?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Fecha de nacimiento' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Es cliente previo?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Pais de residencia' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Pais emisor de pasaporte' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>
                                                  País emisor de pasaporte:
                                                </p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Provincia de destino' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>
                                                  Provincia de destino:
                                                </p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            
                                          </li>
                                        )
                                    )}
                                  </ul>
                                  <ul
                                    className='cotization-form'
                                    style={{display: 'flex', flexDirection: 'column'}}
                                  >
                                    {Object.entries(answer).map(
                                      ([key, value]) =>
                                        value !== null &&
                                        value !== undefined &&
                                        (typeof value !== 'string' || value.trim() !== '') && (
                                          <li key={key}>
                                            <div style={{gap: '10px'}}>
                                              {key === 'Estado civil' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Codigo de descuento' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === '¿Tiene biometricos?' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Direccion' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Email' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Numero de telefono' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Validador oferta laboral' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>
                                                    Validador Oferta Laboral:
                                                  </p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                            </div>
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </div>
                              ))}
                          </div>
                        )}
                        {memberVisibility[member.id] && (
                          <div>
                            {member.answers &&
                              member.answers.map((answer: any) => (
                                <div className='container-all-questions' key={answer.id}>
                                  <ul
                                    className='cotization-form'
                                    style={{display: 'flex', flexDirection: 'column'}}
                                  >
                                    {Object.entries(answer).map(
                                      ([key, value]) =>
                                        value !== null &&
                                        value !== undefined &&
                                        (typeof value !== 'string' || value.trim() !== '') && (
                                          <li key={key}>
                                            {key === 'Nombre' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Apellidos' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Aplico antes?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Ha tenido un rechazo previo?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Fecha de nacimiento' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Validador oferta laboral' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === '¿Es cliente previo?' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Pais de residencia' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>{key}:</p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                            {key === 'Pais emisor de pasaporte' ? (
                                              <div className='container-questions'>
                                                {' '}
                                                <p className='title-question'>
                                                  País emisor de pasaporte:
                                                </p>{' '}
                                                {value as any}
                                              </div>
                                            ) : null}
                                          </li>
                                        )
                                    )}
                                  </ul>
                                  <ul
                                    className='cotization-form'
                                    style={{display: 'flex', flexDirection: 'column'}}
                                  >
                                    {Object.entries(answer).map(
                                      ([key, value]) =>
                                        value !== null &&
                                        value !== undefined &&
                                        (typeof value !== 'string' || value.trim() !== '') && (
                                          <li key={key}>
                                            <div style={{gap: '10px'}}>
                                              {key === 'Estado civil' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Codigo de descuento' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === '¿Tiene biometricos?' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Direccion' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Email' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Numero de telefono' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>{key}:</p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                              {key === 'Pais emisor de pasaporte' ? (
                                                <div className='container-questions'>
                                                  {' '}
                                                  <p className='title-question'>
                                                    País emisor de pasaporte:
                                                  </p>{' '}
                                                  {value as any}
                                                </div>
                                              ) : null}
                                            </div>
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                    <Button
                      onClick={() => {
                        setDiscount({
                          id: '',
                          productContratationId: '',
                          description: '',
                          totalFee: 0,
                          name: 'Codigo de descuento',
                          quantity: 0,
                          fee: 0,
                        })
                        decreaseStep()
                      }}
                      className='startbutton-disc2FamGroup-firmar'
                      style={{
                        width: '8rem',
                        backgroundColor: 'white',
                        color: '#01A9DB',
                        height: '3rem',
                        fontSize: '0.8rem',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', // Sombra más visible,
                        border: 0,
                      }}
                    >
                      <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                        <img src='/icons/pencil-alt.svg' alt='pencil' />
                        <p style={{color: '#01A9DB'}}>
                          <span style={{textTransform: 'uppercase'}}>e</span>
                          <span style={{textTransform: 'lowercase'}}>ditar</span>
                        </p>
                      </div>
                    </Button>
                  </div>
                </div>
                <div className='title-cotization'>
                  <div style={{marginTop: '120px'}} className='container-total-price'>
                    <p>Tu contratacion con Immiland </p>
                    <p style={{color: '#1A8245', marginBottom: '1rem'}}>tiene un valor total de</p>
                    <CalculateTotalsNew />
                  </div>
                  <div className='container-product-list'>
                    <p style={{width: '100%'}}>Incluye lo siguiente:</p>
                    {getBiometrics().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getBiometrics().quantity > 0 ? (
                              <>
                                {getBiometrics().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getBiometrics().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getBiometrics().quantity > 0 ? getBiometrics().description : null}
                          </div>
                        </div>
                        {getBiometrics().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getBiometrics().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getBiometrics().totalFee)}
                              {getBiometrics().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getdependantChildIndependentSponsor().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getdependantChildIndependentSponsor().quantity > 0 ? (
                              <>
                                {getdependantChildIndependentSponsor().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getdependantChildIndependentSponsor().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getdependantChildIndependentSponsor().quantity > 0
                              ? getdependantChildIndependentSponsor().description
                              : null}
                          </div>
                        </div>
                        {getdependantChildIndependentSponsor().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getdependantChildIndependentSponsor().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getdependantChildIndependentSponsor().totalFee)}
                              {getdependantChildIndependentSponsor().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getDependentPN().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getDependentPN().quantity > 0 ? (
                              <>
                                {getDependentPN().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getDependentPN().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getDependentPN().quantity > 0 ? getDependentPN().description : null}
                          </div>
                        </div>
                        {getDependentPN().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getDependentPN().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getDependentPN().totalFee)}
                              {getDependentPN().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPN().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPN().quantity > 0 ? (
                              <>
                                {getPN().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPN().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPN().quantity > 0 ? getPN().description : null}
                          </div>
                        </div>
                        {getPN().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getPN().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getPN().totalFee)}
                              {getPN().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getOpenWorkPermitVulnerable().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getOpenWorkPermitVulnerable().quantity > 0 ? (
                              <>
                                {getOpenWorkPermitVulnerable().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getOpenWorkPermitVulnerable().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getOpenWorkPermitVulnerable().quantity > 0
                              ? getOpenWorkPermitVulnerable().description
                              : null}
                          </div>
                        </div>
                        {getOpenWorkPermitVulnerable().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getOpenWorkPermitVulnerable().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getOpenWorkPermitVulnerable().totalFee)}
                              {getOpenWorkPermitVulnerable().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPNPProgram().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPNPProgram().quantity > 0 ? (
                              <>
                                {getPNPProgram().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPNPProgram().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPNPProgram().quantity > 0 ? getPNPProgram().description : null}
                          </div>
                        </div>
                        {getPNPProgram().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPNPProgram().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPNPProgram().totalFee)}
                              {getPNPProgram().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getSpousePN().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSpousePN().quantity > 0 ? (
                              <>
                                {getSpousePN().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSpousePN().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSpousePN().quantity > 0 ? getSpousePN().description : null}
                          </div>
                        </div>
                        {getSpousePN().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSpousePN().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSpousePN().totalFee)}
                              {getSpousePN().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getSpouseExEntry().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSpouseExEntry().quantity > 0 ? (
                              <>
                                {getSpouseExEntry().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSpouseExEntry().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSpouseExEntry().quantity > 0
                              ? getSpouseExEntry().description
                              : null}
                          </div>
                        </div>
                        {getSpouseExEntry().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSpouseExEntry().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSpouseExEntry().totalFee)}
                              {getSpouseExEntry().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getdependantExEntry().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getdependantExEntry().quantity > 0 ? (
                              <>
                                {getdependantExEntry().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getdependantExEntry().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getdependantExEntry().quantity > 0
                              ? getdependantExEntry().description
                              : null}
                          </div>
                        </div>
                        {getdependantExEntry().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getdependantExEntry().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getdependantExEntry().totalFee)}
                              {getdependantExEntry().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getExpressEntryPhaseTwo().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getExpressEntryPhaseTwo().quantity > 0 ? (
                              <>
                                {getExpressEntryPhaseTwo().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getExpressEntryPhaseTwo().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getExpressEntryPhaseTwo().quantity > 0
                              ? getExpressEntryPhaseTwo().description
                              : null}
                          </div>
                        </div>
                        {getExpressEntryPhaseTwo().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getExpressEntryPhaseTwo().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getExpressEntryPhaseTwo().totalFee)}
                              {getExpressEntryPhaseTwo().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPReconomicPA().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPReconomicPA().quantity > 0 ? (
                              <>
                                {getPReconomicPA().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPReconomicPA().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPReconomicPA().quantity > 0 ? getPReconomicPA().description : null}
                          </div>
                        </div>
                        {getPReconomicPA().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPReconomicPA().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPReconomicPA().totalFee)}
                              {getPReconomicPA().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPReconomicSpouse().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPReconomicSpouse().quantity > 0 ? (
                              <>
                                {getPReconomicSpouse().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPReconomicSpouse().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPReconomicSpouse().quantity > 0
                              ? getPReconomicSpouse().description
                              : null}
                          </div>
                        </div>
                        {getPReconomicSpouse().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPReconomicSpouse().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPReconomicSpouse().totalFee)}
                              {getPReconomicSpouse().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPReconomicDepChild().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPReconomicDepChild().quantity > 0 ? (
                              <>
                                {getPReconomicDepChild().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPReconomicDepChild().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPReconomicDepChild().quantity > 0
                              ? getPReconomicDepChild().description
                              : null}
                          </div>
                        </div>
                        {getPReconomicDepChild().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPReconomicDepChild().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPReconomicDepChild().totalFee)}
                              {getPReconomicDepChild().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getQCsponsorship().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getQCsponsorship().quantity > 0 ? (
                              <>
                                {getQCsponsorship().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getQCsponsorship().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getQCsponsorship().quantity > 0
                              ? getQCsponsorship().description
                              : null}
                          </div>
                        </div>
                        {getQCsponsorship().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getQCsponsorship().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getQCsponsorship().totalFee)}
                              {getQCsponsorship().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getQCsponsorshipFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getQCsponsorshipFee().quantity > 0 ? (
                              <>
                                {getQCsponsorshipFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getQCsponsorshipFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getQCsponsorshipFee().quantity > 0
                              ? getQCsponsorshipFee().description
                              : null}
                          </div>
                        </div>
                        {getQCsponsorshipFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getQCsponsorshipFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getQCsponsorshipFee().totalFee)}
                              {getQCsponsorshipFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getQCAddMember().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getQCAddMember().quantity > 0 ? (
                              <>
                                {getQCAddMember().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getQCAddMember().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getQCAddMember().quantity > 0 ? getQCAddMember().description : null}
                          </div>
                        </div>
                        {getQCAddMember().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getQCAddMember().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getQCAddMember().totalFee)}
                              {getQCAddMember().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getResidenceSponsorshipFeeParents().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getResidenceSponsorshipFeeParents().quantity > 0 ? (
                              <>
                                {getResidenceSponsorshipFeeParents().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getResidenceSponsorshipFeeParents().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getResidenceSponsorshipFeeParents().quantity > 0
                              ? getResidenceSponsorshipFeeParents().description
                              : null}
                          </div>
                        </div>
                        {getResidenceSponsorshipFeeParents().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getResidenceSponsorshipFeeParents().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getResidenceSponsorshipFeeParents().totalFee)}
                              {getResidenceSponsorshipFeeParents().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getVisitorVisaDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getVisitorVisaDetails().quantity > 0 ? (
                              <>
                                {getVisitorVisaDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getVisitorVisaDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorVisaDetails().quantity > 0
                              ? getVisitorVisaDetails().description
                              : null}
                          </div>
                        </div>
                        {getVisitorVisaDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getVisitorVisaDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getVisitorVisaDetails().totalFee)}
                              {getVisitorVisaDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getVisitorVisaDetailsLessThanSixMonths().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getVisitorVisaDetailsLessThanSixMonths().quantity > 0 ? (
                              <>
                                {getVisitorVisaDetailsLessThanSixMonths().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getVisitorVisaDetailsLessThanSixMonths().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorVisaDetailsLessThanSixMonths().quantity > 0
                              ? getVisitorVisaDetailsLessThanSixMonths().description
                              : null}
                          </div>
                        </div>
                        {getVisitorVisaDetailsLessThanSixMonths().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getVisitorVisaDetailsLessThanSixMonths().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(getVisitorVisaDetailsLessThanSixMonths().totalFee)}
                              {getVisitorVisaDetailsLessThanSixMonths().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getWorkingHolidayFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkingHolidayFee().quantity > 0 ? (
                              <>
                                {getWorkingHolidayFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkingHolidayFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorVisaDetails().quantity > 0
                              ? getWorkingHolidayFee().description
                              : null}
                          </div>
                        </div>
                        {getWorkingHolidayFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkingHolidayFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getWorkingHolidayFee().totalFee)}
                              {getWorkingHolidayFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getInternationalExperienceCanadaWorkPermit().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getInternationalExperienceCanadaWorkPermit().quantity > 0 ? (
                              <>
                                {getInternationalExperienceCanadaWorkPermit().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getInternationalExperienceCanadaWorkPermit().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorVisaDetails().quantity > 0
                              ? getInternationalExperienceCanadaWorkPermit().description
                              : null}
                          </div>
                        </div>
                        {getInternationalExperienceCanadaWorkPermit().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getInternationalExperienceCanadaWorkPermit().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getInternationalExperienceCanadaWorkPermit().totalFee
                              )}
                              {getInternationalExperienceCanadaWorkPermit().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getVisitorFederal().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getVisitorFederal().quantity > 0 ? (
                              <>
                                {getVisitorFederal().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getVisitorFederal().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorFederal().quantity > 0
                              ? getVisitorFederal().description
                              : null}
                          </div>
                        </div>
                        {getVisitorFederal().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getVisitorFederal().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getVisitorFederal().totalFee)}
                              {getVisitorFederal().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getVisitorRecord().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getVisitorRecord().quantity > 0 ? (
                              <>
                                {getVisitorRecord().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getVisitorRecord().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorRecord().quantity > 0
                              ? getVisitorRecord().description
                              : null}
                          </div>
                        </div>
                        {getVisitorRecord().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getVisitorRecord().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getVisitorRecord().totalFee)}
                              {getVisitorRecord().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getVisitorRecordFederal().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getVisitorRecordFederal().quantity > 0 ? (
                              <>
                                {getVisitorRecordFederal().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getVisitorRecordFederal().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getVisitorRecordFederal().quantity > 0
                              ? getVisitorRecordFederal().description
                              : null}
                          </div>
                        </div>
                        {getVisitorRecordFederal().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getVisitorRecordFederal().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getVisitorRecordFederal().totalFee)}
                              {getVisitorRecordFederal().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getTransitVisaDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getTransitVisaDetails().quantity > 0 ? (
                              <>
                                {getTransitVisaDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getTransitVisaDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getTransitVisaDetails().quantity > 0
                              ? getTransitVisaDetails().description
                              : null}
                          </div>
                        </div>
                        {getTransitVisaDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getTransitVisaDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getTransitVisaDetails().totalFee)}
                              {getTransitVisaDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getTransitVisaDetailsFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getTransitVisaDetailsFee().quantity > 0 ? (
                              <>
                                {getTransitVisaDetailsFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getTransitVisaDetailsFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getTransitVisaDetailsFee().quantity > 0
                              ? getTransitVisaDetailsFee().description
                              : null}
                          </div>
                        </div>
                        {getTransitVisaDetailsFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getTransitVisaDetailsFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getTransitVisaDetailsFee().totalFee)}
                              {getTransitVisaDetailsFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getSuperVisaDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSuperVisaDetails().quantity > 0 ? (
                              <>
                                {getSuperVisaDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSuperVisaDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSuperVisaDetails().quantity > 0
                              ? getSuperVisaDetails().description
                              : null}
                          </div>
                        </div>
                        {getSuperVisaDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSuperVisaDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSuperVisaDetails().totalFee)}
                              {getSuperVisaDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getLMIAExExtentionPOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getLMIAExExtentionPOE().quantity > 0 ? (
                              <>
                                {getLMIAExExtentionPOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getLMIAExExtentionPOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getLMIAExExtentionPOE().quantity > 0
                              ? getLMIAExExtentionPOE().description
                              : null}
                          </div>
                        </div>
                        {getLMIAExExtentionPOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getLMIAExExtentionPOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getLMIAExExtentionPOE().totalFee)}
                              {getLMIAExExtentionPOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getSuperVisaFederalFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSuperVisaFederalFee().quantity > 0 ? (
                              <>
                                {getSuperVisaFederalFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSuperVisaFederalFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSuperVisaFederalFee().quantity > 0
                              ? getSuperVisaFederalFee().description
                              : null}
                          </div>
                        </div>
                        {getSuperVisaFederalFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSuperVisaFederalFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSuperVisaFederalFee().totalFee)}
                              {getSuperVisaFederalFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getRSWPphaseone().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getRSWPphaseone().quantity > 0 ? (
                              <>
                                {getRSWPphaseone().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getRSWPphaseone().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getRSWPphaseone().quantity > 0 ? getRSWPphaseone().description : null}
                          </div>
                        </div>
                        {getRSWPphaseone().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getRSWPphaseone().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getRSWPphaseone().totalFee)}
                              {getRSWPphaseone().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getspouseRSWPphaseone().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getspouseRSWPphaseone().quantity > 0 ? (
                              <>
                                {getspouseRSWPphaseone().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getspouseRSWPphaseone().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getspouseRSWPphaseone().quantity > 0
                              ? getspouseRSWPphaseone().description
                              : null}
                          </div>
                        </div>
                        {getspouseRSWPphaseone().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getspouseRSWPphaseone().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getspouseRSWPphaseone().totalFee)}
                              {getspouseRSWPphaseone().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQDependantChild().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQDependantChild().quantity > 0 ? (
                              <>
                                {getCSQDependantChild().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQDependantChild().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQDependantChild().quantity > 0
                              ? getCSQDependantChild().description
                              : null}
                          </div>
                        </div>
                        {getCSQDependantChild().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCSQDependantChild().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQDependantChild().totalFee)}
                              {getCSQDependantChild().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQpr().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQpr().quantity > 0 ? (
                              <>
                                {getCSQpr().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQpr().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQpr().quantity > 0 ? getCSQpr().description : null}
                          </div>
                        </div>
                        {getCSQpr().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getCSQpr().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQpr().totalFee)}
                              {getCSQpr().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getoffertamplate().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getoffertamplate().quantity > 0 ? (
                              <>
                                {getoffertamplate().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getoffertamplate().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getoffertamplate().quantity > 0
                              ? getoffertamplate().description
                              : null}
                          </div>
                        </div>
                        {getoffertamplate().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getoffertamplate().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getoffertamplate().totalFee)}
                              {getoffertamplate().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getdepedantotherPRFederal().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getdepedantotherPRFederal().quantity > 0 ? (
                              <>
                                {getdepedantotherPRFederal().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getdepedantotherPRFederal().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getdepedantotherPRFederal().quantity > 0
                              ? getdepedantotherPRFederal().description
                              : null}
                          </div>
                        </div>
                        {getdepedantotherPRFederal().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getdepedantotherPRFederal().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getdepedantotherPRFederal().totalFee)}
                              {getdepedantotherPRFederal().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getotherPRFederal().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getotherPRFederal().quantity > 0 ? (
                              <>
                                {getotherPRFederal().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getotherPRFederal().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getotherPRFederal().quantity > 0
                              ? getotherPRFederal().description
                              : null}
                          </div>
                        </div>
                        {getotherPRFederal().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getotherPRFederal().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getotherPRFederal().totalFee)}
                              {getotherPRFederal().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getspouseotherPRFederal().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getspouseotherPRFederal().quantity > 0 ? (
                              <>
                                {getspouseotherPRFederal().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getspouseotherPRFederal().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getspouseotherPRFederal().quantity > 0
                              ? getspouseotherPRFederal().description
                              : null}
                          </div>
                        </div>
                        {getspouseotherPRFederal().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getspouseotherPRFederal().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getspouseotherPRFederal().totalFee)}
                              {getspouseotherPRFederal().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getExpiredRestoration().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getExpiredRestoration().quantity > 0 ? (
                              <>
                                {getExpiredRestoration().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getExpiredRestoration().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getExpiredRestoration().quantity > 0
                              ? getExpiredRestoration().description
                              : null}
                          </div>
                        </div>
                        {getExpiredRestoration().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getExpiredRestoration().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getExpiredRestoration().totalFee)}
                              {getExpiredRestoration().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getExpiredPermit().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getExpiredPermit().quantity > 0 ? (
                              <>
                                {getExpiredPermit().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getExpiredPermit().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getExpiredPermit().quantity > 0
                              ? getExpiredPermit().description
                              : null}
                          </div>
                        </div>
                        {getExpiredPermit().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getExpiredPermit().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getExpiredPermit().totalFee)}
                              {getExpiredPermit().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getAtip().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getAtip().quantity > 0 ? (
                              <>
                                {getAtip().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getAtip().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getAtip().quantity > 0 ? getAtip().description : null}
                          </div>
                        </div>
                        {getAtip().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getAtip().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getAtip().totalFee)}
                              {getAtip().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCitizenship().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCitizenship().quantity > 0 ? (
                              <>
                                {getCitizenship().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCitizenship().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCitizenship().quantity > 0 ? getCitizenship().description : null}
                          </div>
                        </div>
                        {getCitizenship().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCitizenship().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCitizenship().totalFee)}
                              {getCitizenship().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getDocumentReplacement().quantity > 0 ? (
                      <>
                        <div className='complete-product'>
                          <div className='container-name-description'>
                            <div className='name-product'>
                              {getDocumentReplacement().quantity > 0 ? (
                                <>
                                  {getDocumentReplacement().type !== 'gf' ? (
                                    <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                  ) : (
                                    <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                  )}
                                  {getDocumentReplacement().name}
                                </>
                              ) : null}
                            </div>
                            <div className='name-description'>
                              {getDocumentReplacement().quantity > 0
                                ? getDocumentReplacement().description
                                : null}
                            </div>
                          </div>
                          {getDocumentReplacement().quantity > 0 && (
                            <div className='container-price-quantity'>
                              <div className='quantity-product'>
                                Cantidad: {getDocumentReplacement().quantity}
                              </div>
                              <div className='price-product'>
                                CAD {formatter.format(getDocumentReplacement().totalFee)}
                                {getDocumentReplacement().type !== 'gf' && (
                                  <span
                                    className='exclamation-circle'
                                    onClick={serviceValue}
                                    style={{cursor: 'pointer'}}
                                  >
                                    <span style={circleStyle}>!</span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='complete-product'>
                          <div className='container-name-description'>
                            <div className='name-product'>
                              {getDocumentReplacementGF().quantity > 0 ? (
                                <>
                                  {getDocumentReplacementGF().type !== 'gf' ? (
                                    <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                  ) : (
                                    <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                  )}
                                  {getDocumentReplacementGF().name}
                                </>
                              ) : null}
                            </div>
                            <div className='name-description'>
                              {getDocumentReplacementGF().quantity > 0
                                ? getDocumentReplacementGF().description
                                : null}
                            </div>
                          </div>
                          {getDocumentReplacementGF().quantity > 0 && (
                            <div className='container-price-quantity'>
                              <div className='quantity-product'>
                                Cantidad: {getDocumentReplacementGF().quantity}
                              </div>
                              <div className='price-product'>
                                CAD {formatter.format(getDocumentReplacementGF().totalFee)}
                                {getDocumentReplacementGF().type !== 'gf' && (
                                  <span
                                    className='exclamation-circle'
                                    onClick={serviceValue}
                                    style={{cursor: 'pointer'}}
                                  >
                                    <span style={circleStyle}>!</span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}

                    {getDocumentAmendment().quantity > 0 ? (
                      <>
                        <div className='complete-product'>
                          <div className='container-name-description'>
                            <div className='name-product'>
                              {getDocumentAmendment().quantity > 0 ? (
                                <>
                                  {getDocumentAmendment().type !== 'gf' ? (
                                    <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                  ) : (
                                    <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                  )}
                                  {getDocumentAmendment().name}
                                </>
                              ) : null}
                            </div>
                            <div className='name-description'>
                              {getDocumentAmendment().quantity > 0
                                ? getDocumentAmendment().description
                                : null}
                            </div>
                          </div>
                          {getDocumentAmendment().quantity > 0 && (
                            <div className='container-price-quantity'>
                              <div className='quantity-product'>
                                Cantidad: {getDocumentAmendment().quantity}
                              </div>
                              <div className='price-product'>
                                CAD {formatter.format(getDocumentAmendment().totalFee)}
                                {getDocumentAmendment().type !== 'gf' && (
                                  <span
                                    className='exclamation-circle'
                                    onClick={serviceValue}
                                    style={{cursor: 'pointer'}}
                                  >
                                    <span style={circleStyle}>!</span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}

                    {getCitizenshipAdult().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCitizenshipAdult().quantity > 0 ? (
                              <>
                                {getCitizenshipAdult().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCitizenshipAdult().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCitizenshipAdult().quantity > 0
                              ? getCitizenship().description
                              : null}
                          </div>
                        </div>
                        {getCitizenshipAdult().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCitizenshipAdult().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCitizenshipAdult().totalFee)}
                              {getCitizenshipAdult().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCitizenshipMinor().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCitizenshipMinor().quantity > 0 ? (
                              <>
                                {getCitizenshipMinor().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCitizenshipMinor().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCitizenshipMinor().quantity > 0
                              ? getCitizenshipMinor().description
                              : null}
                          </div>
                        </div>
                        {getCitizenshipMinor().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCitizenshipMinor().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCitizenshipMinor().totalFee)}
                              {getCitizenshipMinor().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getAtipFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getAtipFee().quantity > 0 ? (
                              <>
                                {getAtipFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getAtipFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getAtipFee().quantity > 0 ? getAtipFee().description : null}
                          </div>
                        </div>
                        {getAtipFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getAtipFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getAtipFee().totalFee)}
                              {getAtipFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getExpiredPermitMoreThan90().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getExpiredPermitMoreThan90().quantity > 0 ? (
                              <>
                                {getExpiredPermitMoreThan90().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getExpiredPermitMoreThan90().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getExpiredPermitMoreThan90().quantity > 0
                              ? getExpiredPermitMoreThan90().description
                              : null}
                          </div>
                        </div>
                        {getExpiredPermitMoreThan90().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getExpiredPermitMoreThan90().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getExpiredPermitMoreThan90().totalFee)}
                              {getExpiredPermitMoreThan90().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQSpouseParnerFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQSpouseParnerFee().quantity > 0 ? (
                              <>
                                {getCSQSpouseParnerFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQSpouseParnerFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQSpouseParnerFee().quantity > 0
                              ? getCSQSpouseParnerFee().description
                              : null}
                          </div>
                        </div>
                        {getCSQSpouseParnerFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCSQSpouseParnerFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQSpouseParnerFee().totalFee)}
                              {getCSQSpouseParnerFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQSpouseParner().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQSpouseParner().quantity > 0 ? (
                              <>
                                {getCSQSpouseParner().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQSpouseParner().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQSpouseParner().quantity > 0
                              ? getCSQSpouseParner().description
                              : null}
                          </div>
                        </div>
                        {getCSQSpouseParner().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCSQSpouseParner().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQSpouseParner().totalFee)}
                              {getCSQSpouseParner().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getDependantVisitorLessFive().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getDependantVisitorLessFive().quantity > 0 ? (
                              <>
                                {getDependantVisitorLessFive().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getDependantVisitorLessFive().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getDependantVisitorLessFive().quantity > 0
                              ? getDependantVisitorLessFive().description
                              : null}
                          </div>
                        </div>
                        {getDependantVisitorLessFive().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getDependantVisitorLessFive().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getDependantVisitorLessFive().totalFee)}
                              {getDependantVisitorLessFive().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getDependantStudyPermitExtentionOverFiveDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getDependantStudyPermitExtentionOverFiveDetails().quantity > 0 ? (
                              <>
                                {getDependantStudyPermitExtentionOverFiveDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getDependantStudyPermitExtentionOverFiveDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getDependantStudyPermitExtentionOverFiveDetails().quantity > 0
                              ? getDependantStudyPermitExtentionOverFiveDetails().description
                              : null}
                          </div>
                        </div>
                        {getDependantStudyPermitExtentionOverFiveDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getDependantStudyPermitExtentionOverFiveDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getDependantStudyPermitExtentionOverFiveDetails().totalFee
                              )}
                              {getDependantStudyPermitExtentionOverFiveDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getdependantStudyPermitExtentionLessFiveDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getdependantStudyPermitExtentionLessFiveDetails().quantity > 0 ? (
                              <>
                                {getdependantStudyPermitExtentionLessFiveDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getdependantStudyPermitExtentionLessFiveDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getdependantStudyPermitExtentionLessFiveDetails().quantity > 0
                              ? getdependantStudyPermitExtentionLessFiveDetails().description
                              : null}
                          </div>
                        </div>
                        {getdependantStudyPermitExtentionLessFiveDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getdependantStudyPermitExtentionLessFiveDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getdependantStudyPermitExtentionLessFiveDetails().totalFee
                              )}
                              {getdependantStudyPermitExtentionLessFiveDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getSpousePartnerOpenWorkPermitExtentionON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSpousePartnerOpenWorkPermitExtentionON().quantity > 0 ? (
                              <>
                                {getSpousePartnerOpenWorkPermitExtentionON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSpousePartnerOpenWorkPermitExtentionON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSpousePartnerOpenWorkPermitExtentionON().quantity > 0
                              ? getSpousePartnerOpenWorkPermitExtentionON().description
                              : null}
                          </div>
                        </div>
                        {getSpousePartnerOpenWorkPermitExtentionON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSpousePartnerOpenWorkPermitExtentionON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getSpousePartnerOpenWorkPermitExtentionON().totalFee
                              )}
                              {getSpousePartnerOpenWorkPermitExtentionON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getTravelDocumentRenovationStickerVisaON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getTravelDocumentRenovationStickerVisaON().quantity > 0 ? (
                              <>
                                {getTravelDocumentRenovationStickerVisaON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getTravelDocumentRenovationStickerVisaON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getTravelDocumentRenovationStickerVisaON().quantity > 0
                              ? getTravelDocumentRenovationStickerVisaON().description
                              : null}
                          </div>
                        </div>
                        {getTravelDocumentRenovationStickerVisaON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getTravelDocumentRenovationStickerVisaON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getTravelDocumentRenovationStickerVisaON().totalFee
                              )}
                              {getTravelDocumentRenovationStickerVisaON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getStudyPermitExtentionON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getStudyPermitExtentionON().quantity > 0 ? (
                              <>
                                {getStudyPermitExtentionON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getStudyPermitExtentionON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getStudyPermitExtentionON().quantity > 0
                              ? getStudyPermitExtentionON().description
                              : null}
                          </div>
                        </div>
                        {getStudyPermitExtentionON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getStudyPermitExtentionON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getStudyPermitExtentionON().totalFee)}
                              {getStudyPermitExtentionON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getStudyLanguagePermitExtentionON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getStudyLanguagePermitExtentionON().quantity > 0 ? (
                              <>
                                {getStudyLanguagePermitExtentionON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getStudyLanguagePermitExtentionON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getStudyLanguagePermitExtentionON().quantity > 0
                              ? getStudyLanguagePermitExtentionON().description
                              : null}
                          </div>
                        </div>
                        {getStudyLanguagePermitExtentionON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getStudyLanguagePermitExtentionON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getStudyLanguagePermitExtentionON().totalFee)}
                              {getStudyLanguagePermitExtentionON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getSubmission().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSubmission().quantity > 0 ? (
                              <>
                                {getSubmission().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSubmission().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSubmission().quantity > 0 ? getSubmission().description : null}
                          </div>
                        </div>
                        {getSubmission().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSubmission().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSubmission().totalFee)}
                              {getSubmission().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getEtaDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getEtaDetails().quantity > 0 ? (
                              <>
                                {getEtaDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getEtaDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getEtaDetails().quantity > 0 ? getEtaDetails().description : null}
                          </div>
                        </div>
                        {getEtaDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getEtaDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getEtaDetails().totalFee)}
                              {getEtaDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getEtaFederalFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getEtaFederalFee().quantity > 0 ? (
                              <>
                                {getEtaFederalFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getEtaFederalFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getEtaFederalFee().quantity > 0
                              ? getEtaFederalFee().description
                              : null}
                          </div>
                        </div>
                        {getEtaFederalFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getEtaFederalFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getEtaFederalFee().totalFee)}
                              {getEtaFederalFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getsetCalculatestudyPermitAcademicDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getsetCalculatestudyPermitAcademicDetails().quantity > 0 ? (
                              <>
                                {getsetCalculatestudyPermitAcademicDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getsetCalculatestudyPermitAcademicDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getsetCalculatestudyPermitAcademicDetails().quantity > 0
                              ? getsetCalculatestudyPermitAcademicDetails().description
                              : null}
                          </div>
                        </div>
                        {getsetCalculatestudyPermitAcademicDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getsetCalculatestudyPermitAcademicDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getsetCalculatestudyPermitAcademicDetails().totalFee
                              )}
                              {getsetCalculatestudyPermitAcademicDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCalculateCAQStudent().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQStudent().quantity > 0 ? (
                              <>
                                {getCalculateCAQStudent().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQStudent().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQStudent().quantity > 0
                              ? getCalculateCAQStudent().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQStudent().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQStudent().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQStudent().totalFee)}
                              {getCalculateCAQStudent().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCalculatestudyPermitFederalFeeDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculatestudyPermitFederalFeeDetails().quantity > 0 ? (
                              <>
                                {getCalculatestudyPermitFederalFeeDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculatestudyPermitFederalFeeDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculatestudyPermitFederalFeeDetails().quantity > 0
                              ? getCalculatestudyPermitFederalFeeDetails().description
                              : null}
                          </div>
                        </div>
                        {getCalculatestudyPermitFederalFeeDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculatestudyPermitFederalFeeDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getCalculatestudyPermitFederalFeeDetails().totalFee
                              )}
                              {getCalculatestudyPermitFederalFeeDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCalculatestudyPermitLangDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculatestudyPermitLangDetails().quantity > 0 ? (
                              <>
                                {getCalculatestudyPermitLangDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculatestudyPermitLangDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculatestudyPermitLangDetails().quantity > 0
                              ? getCalculatestudyPermitLangDetails().description
                              : null}
                          </div>
                        </div>
                        {getCalculatestudyPermitLangDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculatestudyPermitLangDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculatestudyPermitLangDetails().totalFee)}
                              {getCalculatestudyPermitLangDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCalculatestudyPermitAcademicStreamDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculatestudyPermitAcademicStreamDetails().quantity > 0 ? (
                              <>
                                {getCalculatestudyPermitAcademicStreamDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculatestudyPermitAcademicStreamDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculatestudyPermitAcademicStreamDetails().quantity > 0
                              ? getCalculatestudyPermitAcademicStreamDetails().description
                              : null}
                          </div>
                        </div>
                        {getCalculatestudyPermitAcademicStreamDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculatestudyPermitAcademicStreamDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getCalculatestudyPermitAcademicStreamDetails().totalFee
                              )}
                              {getCalculatestudyPermitAcademicStreamDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getDependantVisitorPlusFive().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getDependantVisitorPlusFive().quantity > 0 ? (
                              <>
                                {getDependantVisitorPlusFive().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getDependantVisitorPlusFive().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getDependantVisitorPlusFive().quantity > 0
                              ? getDependantVisitorPlusFive().description
                              : null}
                          </div>
                        </div>
                        {getDependantVisitorPlusFive().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getDependantVisitorPlusFive().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getDependantVisitorPlusFive().totalFee)}
                              {getDependantVisitorPlusFive().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPartnerOpenWorkSpouse().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPartnerOpenWorkSpouse().quantity > 0 ? (
                              <>
                                {getPartnerOpenWorkSpouse().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPartnerOpenWorkSpouse().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPartnerOpenWorkSpouse().quantity > 0
                              ? getPartnerOpenWorkSpouse().description
                              : null}
                          </div>
                        </div>
                        {getPartnerOpenWorkSpouse().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPartnerOpenWorkSpouse().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPartnerOpenWorkSpouse().totalFee)}
                              {getPartnerOpenWorkSpouse().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPartnerOpenWorkPermitPOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPartnerOpenWorkPermitPOE().quantity > 0 ? (
                              <>
                                {getPartnerOpenWorkPermitPOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPartnerOpenWorkPermitPOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPartnerOpenWorkPermitPOE().quantity > 0
                              ? getPartnerOpenWorkPermitPOE().description
                              : null}
                          </div>
                        </div>
                        {getPartnerOpenWorkPermitPOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPartnerOpenWorkPermitPOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPartnerOpenWorkPermitPOE().totalFee)}
                              {getPartnerOpenWorkPermitPOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCAQStudentGovernmentFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCAQStudentGovernmentFee().quantity > 0 ? (
                              <>
                                {getCAQStudentGovernmentFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCAQStudentGovernmentFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCAQStudentGovernmentFee().quantity > 0
                              ? getCAQStudentGovernmentFee().description
                              : null}
                          </div>
                        </div>
                        {getCAQStudentGovernmentFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCAQStudentGovernmentFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCAQStudentGovernmentFee().totalFee)}
                              {getCAQStudentGovernmentFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCalculateCAQExtentionStudent().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQExtentionStudent().quantity > 0 ? (
                              <>
                                {getCalculateCAQExtentionStudent().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQExtentionStudent().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQExtentionStudent().quantity > 0
                              ? getCalculateCAQExtentionStudent().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQExtentionStudent().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQExtentionStudent().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQExtentionStudent().totalFee)}
                              {getCalculateCAQExtentionStudent().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCalculateCAQMinor().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQMinor().quantity > 0 ? (
                              <>
                                {getCalculateCAQMinor().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQMinor().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQMinor().quantity > 0
                              ? getCalculateCAQMinor().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQMinor().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQMinor().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQMinor().totalFee)}
                              {getCalculateCAQMinor().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCalculateCAQMinorGovermentFees().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQMinorGovermentFees().quantity > 0 ? (
                              <>
                                {getCalculateCAQMinorGovermentFees().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQMinorGovermentFees().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQMinorGovermentFees().quantity > 0
                              ? getCalculateCAQMinorGovermentFees().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQMinorGovermentFees().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQMinorGovermentFees().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQMinorGovermentFees().totalFee)}
                              {getCalculateCAQMinorGovermentFees().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCalculateCAQWorker().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQWorker().quantity > 0 ? (
                              <>
                                {getCalculateCAQWorker().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQWorker().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQWorker().quantity > 0
                              ? getCalculateCAQWorker().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQWorker().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQWorker().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQWorker().totalFee)}
                              {getCalculateCAQWorker().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCalculateCAQWorkerGovermentFees().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCalculateCAQWorkerGovermentFees().quantity > 0 ? (
                              <>
                                {getCalculateCAQWorkerGovermentFees().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCalculateCAQWorkerGovermentFees().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCalculateCAQWorkerGovermentFees().quantity > 0
                              ? getCalculateCAQWorkerGovermentFees().description
                              : null}
                          </div>
                        </div>
                        {getCalculateCAQWorkerGovermentFees().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCalculateCAQWorkerGovermentFees().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCalculateCAQWorkerGovermentFees().totalFee)}
                              {getCalculateCAQWorkerGovermentFees().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCoop().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCoop().quantity > 0 ? (
                              <>
                                {getCoop().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCoop().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCoop().quantity > 0 ? getCoop().description : null}
                          </div>
                        </div>
                        {getCoop().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getCoop().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getCoop().totalFee)}
                              {getCoop().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPartnerWorkingHolidayON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPartnerWorkingHolidayON().quantity > 0 ? (
                              <>
                                {getPartnerWorkingHolidayON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPartnerWorkingHolidayON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPartnerWorkingHolidayON().quantity > 0
                              ? getPartnerWorkingHolidayON().description
                              : null}
                          </div>
                        </div>
                        {getPartnerWorkingHolidayON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPartnerWorkingHolidayON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPartnerWorkingHolidayON().totalFee)}
                              {getPartnerWorkingHolidayON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getOpenWorkPermitHolderFederalFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getOpenWorkPermitHolderFederalFee().quantity > 0 ? (
                              <>
                                {getOpenWorkPermitHolderFederalFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getOpenWorkPermitHolderFederalFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getOpenWorkPermitHolderFederalFee().quantity > 0
                              ? getOpenWorkPermitHolderFederalFee().description
                              : null}
                          </div>
                        </div>
                        {getOpenWorkPermitHolderFederalFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getOpenWorkPermitHolderFederalFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getOpenWorkPermitHolderFederalFee().totalFee)}
                              {getOpenWorkPermitHolderFederalFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getWorkPermitIncludesExtentionFederalFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitIncludesExtentionFederalFee().quantity > 0 ? (
                              <>
                                {getWorkPermitIncludesExtentionFederalFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitIncludesExtentionFederalFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitIncludesExtentionFederalFee().quantity > 0
                              ? getWorkPermitIncludesExtentionFederalFee().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitIncludesExtentionFederalFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitIncludesExtentionFederalFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getWorkPermitIncludesExtentionFederalFee().totalFee
                              )}
                              {getWorkPermitIncludesExtentionFederalFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getSponsorshipApplicationIn().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSponsorshipApplicationIn().quantity > 0 ? (
                              <>
                                {getSponsorshipApplicationIn().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSponsorshipApplicationIn().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSponsorshipApplicationIn().quantity > 0
                              ? getSponsorshipApplicationIn().description
                              : null}
                          </div>
                        </div>
                        {getSponsorshipApplicationIn().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSponsorshipApplicationIn().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSponsorshipApplicationIn().totalFee)}
                              {getSponsorshipApplicationIn().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPermanentResidenceSponsorshipFederalFee().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPermanentResidenceSponsorshipFederalFee().quantity > 0 ? (
                              <>
                                {getPermanentResidenceSponsorshipFederalFee().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPermanentResidenceSponsorshipFederalFee().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPermanentResidenceSponsorshipFederalFee().quantity > 0
                              ? getPermanentResidenceSponsorshipFederalFee().description
                              : null}
                          </div>
                        </div>
                        {getPermanentResidenceSponsorshipFederalFee().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPermanentResidenceSponsorshipFederalFee().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getPermanentResidenceSponsorshipFederalFee().totalFee
                              )}
                              {getPermanentResidenceSponsorshipFederalFee().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPGWPPOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPGWPPOE().quantity > 0 ? (
                              <>
                                {getPGWPPOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPGWPPOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPGWPPOE().quantity > 0 ? getPGWPPOE().description : null}
                          </div>
                        </div>
                        {getPGWPPOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPGWPPOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPGWPPOE().totalFee)}
                              {getPGWPPOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPGWPEON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPGWPEON().quantity > 0 ? (
                              <>
                                {getPGWPEON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPGWPEON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPGWPEON().quantity > 0 ? getPGWPEON().description : null}
                          </div>
                        </div>
                        {getPGWPEON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getPGWPEON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getPGWPEON().totalFee)}
                              {getPGWPEON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getPGWPEx().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPGWPEx().quantity > 0 ? (
                              <>
                                {getPGWPEx().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getPGWPEx().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPGWPEx().quantity > 0 ? getPGWPEx().description : null}
                          </div>
                        </div>
                        {getPGWPEx().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getPGWPEx().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getPGWPEx().totalFee)}
                              {getPGWPEx().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getOpenWorkPermitMainApplicant().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getOpenWorkPermitMainApplicant().quantity > 0 ? (
                              <>
                                {getOpenWorkPermitMainApplicant().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getOpenWorkPermitMainApplicant().name}
                              </>
                            ) : null}
                          </div>

                          <div className='name-description'>
                            {getOpenWorkPermitMainApplicant().quantity > 0
                              ? getOpenWorkPermitMainApplicant().description
                              : null}
                          </div>
                        </div>
                        {getOpenWorkPermitMainApplicant().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getOpenWorkPermitMainApplicant().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getOpenWorkPermitMainApplicant().totalFee)}
                              {getOpenWorkPermitMainApplicant().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQ().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQ().quantity > 0 ? (
                              <>
                                {getCSQ().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQ().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQ().quantity > 0 ? getCSQ().description : null}
                          </div>
                        </div>
                        {getCSQ().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>Cantidad: {getCSQ().quantity}</div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQ().totalFee)}
                              {getCSQ().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getCSQApplicationFeeMainApplicant().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQApplicationFeeMainApplicant().quantity > 0 ? (
                              <>
                                {getCSQApplicationFeeMainApplicant().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQApplicationFeeMainApplicant().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQApplicationFeeMainApplicant().quantity > 0
                              ? getCSQApplicationFeeMainApplicant().description
                              : null}
                          </div>
                        </div>
                        {getCSQApplicationFeeMainApplicant().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCSQApplicationFeeMainApplicant().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getCSQApplicationFeeMainApplicant().totalFee)}
                              {getCSQApplicationFeeMainApplicant().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getCSQGovernmentApplicationFeeDependentChild().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getCSQGovernmentApplicationFeeDependentChild().quantity > 0 ? (
                              <>
                                {getCSQGovernmentApplicationFeeDependentChild().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getCSQGovernmentApplicationFeeDependentChild().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getCSQGovernmentApplicationFeeDependentChild().quantity > 0
                              ? getCSQGovernmentApplicationFeeDependentChild().description
                              : null}
                          </div>
                        </div>
                        {getCSQGovernmentApplicationFeeDependentChild().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getCSQGovernmentApplicationFeeDependentChild().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getCSQGovernmentApplicationFeeDependentChild().totalFee
                              )}
                              {getCSQGovernmentApplicationFeeDependentChild().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().quantity >
                    0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                              .quantity > 0 ? (
                              <>
                                {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                  .type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {
                                  getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                    .name
                                }
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                              .quantity > 0
                              ? getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                  .description
                              : null}
                          </div>
                        </div>
                        {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                          .quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad:{' '}
                              {
                                getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                  .quantity
                              }
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                  .totalFee
                              )}
                              {getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded()
                                .type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getExpressEntryExpressionOfInterestPhase1().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getExpressEntryExpressionOfInterestPhase1().quantity > 0 ? (
                              <>
                                {getExpressEntryExpressionOfInterestPhase1().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getExpressEntryExpressionOfInterestPhase1().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getExpressEntryExpressionOfInterestPhase1().quantity > 0
                              ? getExpressEntryExpressionOfInterestPhase1().description
                              : null}
                          </div>
                        </div>
                        {getExpressEntryExpressionOfInterestPhase1().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getExpressEntryExpressionOfInterestPhase1().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getExpressEntryExpressionOfInterestPhase1().totalFee
                              )}
                              {getExpressEntryExpressionOfInterestPhase1().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity >
                            0 ? (
                              <>
                                {getSpousePartnerExpressEntryExpressionOfInterestPhase1().type !==
                                'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSpousePartnerExpressEntryExpressionOfInterestPhase1().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity > 0
                              ? getSpousePartnerExpressEntryExpressionOfInterestPhase1().description
                              : null}
                          </div>
                        </div>
                        {getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad:{' '}
                              {getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getSpousePartnerExpressEntryExpressionOfInterestPhase1().totalFee
                              )}
                              {getSpousePartnerExpressEntryExpressionOfInterestPhase1().type !==
                                'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getSponsorshipApplicationOut().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getSponsorshipApplicationOut().quantity > 0 ? (
                              <>
                                {getSponsorshipApplicationOut().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getSponsorshipApplicationOut().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getSponsorshipApplicationOut().quantity > 0
                              ? getSponsorshipApplicationOut().description
                              : null}
                          </div>
                        </div>
                        {getSponsorshipApplicationOut().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getSponsorshipApplicationOut().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getSponsorshipApplicationOut().totalFee)}
                              {getSponsorshipApplicationOut().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().quantity >
                    0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                              .quantity > 0 ? (
                              <>
                                {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                  .type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {
                                  getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                    .name
                                }
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                              .quantity > 0
                              ? getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                  .description
                              : null}
                          </div>
                        </div>
                        {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                          .quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad:{' '}
                              {
                                getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                  .quantity
                              }
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                  .totalFee
                              )}
                              {getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee()
                                .type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getWorkPermitExemptONDetails().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitExemptONDetails().quantity > 0 ? (
                              <>
                                {getWorkPermitExemptONDetails().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitExemptONDetails().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitExemptONDetails().quantity > 0
                              ? getWorkPermitExemptONDetails().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitExemptONDetails().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitExemptONDetails().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getWorkPermitExemptONDetails().totalFee)}
                              {getWorkPermitExemptONDetails().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getWorkPermitExemptONDetailsInsideCanada().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitExemptONDetailsInsideCanada().quantity > 0 ? (
                              <>
                                {getWorkPermitExemptONDetailsInsideCanada().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitExemptONDetailsInsideCanada().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitExemptONDetailsInsideCanada().quantity > 0
                              ? getWorkPermitExemptONDetailsInsideCanada().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitExemptONDetailsInsideCanada().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitExemptONDetailsInsideCanada().quantity}
                            </div>
                            <div className='price-product'>
                              CAD{' '}
                              {formatter.format(
                                getWorkPermitExemptONDetailsInsideCanada().totalFee
                              )}
                              {getWorkPermitExemptONDetailsInsideCanada().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getWorkPermitExemptPOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitExemptPOE().quantity > 0 ? (
                              <>
                                {getWorkPermitExemptPOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitExemptPOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitExemptPOE().quantity > 0
                              ? getWorkPermitExemptPOE().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitExemptPOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitExemptPOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getWorkPermitExemptPOE().totalFee)}
                              {getWorkPermitExemptPOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getWorkPermitPositiveON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitPositiveON().quantity > 0 ? (
                              <>
                                {getWorkPermitPositiveON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitPositiveON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitPositiveON().quantity > 0
                              ? getWorkPermitPositiveON().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitPositiveON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitPositiveON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getWorkPermitPositiveON().totalFee)}
                              {getWorkPermitPositiveON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {getWorkPermitPositivePOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getWorkPermitPositivePOE().quantity > 0 ? (
                              <>
                                {getWorkPermitPositivePOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getWorkPermitPositivePOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getWorkPermitPositivePOE().quantity > 0
                              ? getWorkPermitPositivePOE().description
                              : null}
                          </div>
                        </div>
                        {getWorkPermitPositivePOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getWorkPermitPositivePOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getWorkPermitPositivePOE().totalFee)}
                              {getWorkPermitPositivePOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getLMIAExExtentionON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getLMIAExExtentionON().quantity > 0 ? (
                              <>
                                {getLMIAExExtentionON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getLMIAExExtentionON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getLMIAExExtentionON().quantity > 0
                              ? getLMIAExExtentionON().description
                              : null}
                          </div>
                        </div>
                        {getLMIAExExtentionON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getLMIAExExtentionON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getLMIAExExtentionON().totalFee)}
                              {getLMIAExExtentionON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getLMIAPosExtentionPOE().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getLMIAPosExtentionPOE().quantity > 0 ? (
                              <>
                                {getLMIAPosExtentionPOE().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getLMIAPosExtentionPOE().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getLMIAPosExtentionPOE().quantity > 0
                              ? getLMIAPosExtentionPOE().description
                              : null}
                          </div>
                        </div>
                        {getLMIAPosExtentionPOE().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getLMIAPosExtentionPOE().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getLMIAPosExtentionPOE().totalFee)}
                              {getLMIAPosExtentionPOE().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getLMIAPosExtentionON().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getLMIAPosExtentionON().quantity > 0 ? (
                              <>
                                {getLMIAPosExtentionON().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getLMIAPosExtentionON().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getLMIAPosExtentionON().quantity > 0
                              ? getLMIAPosExtentionON().description
                              : null}
                          </div>
                        </div>
                        {getLMIAPosExtentionON().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getLMIAPosExtentionON().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getLMIAPosExtentionON().totalFee)}
                              {getLMIAPosExtentionON().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getEmploymentletter().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getEmploymentletter().quantity > 0 ? (
                              <>
                                {getEmploymentletter().type !== 'gf' ? (
                                  <img src='/icons/prof-fee.svg' alt='Government Fee' />
                                ) : (
                                  <img src='/icons/gov-fee.svg' alt='Professional Fee' />
                                )}
                                {getEmploymentletter().name}
                              </>
                            ) : null}
                          </div>
                          <div className='name-description'>
                            {getEmploymentletter().quantity > 0
                              ? getEmploymentletter().description
                              : null}
                          </div>
                        </div>
                        {getEmploymentletter().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getEmploymentletter().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getEmploymentletter().totalFee)}
                              {getEmploymentletter().type !== 'gf' && (
                                <span
                                  className='exclamation-circle'
                                  onClick={serviceValue}
                                  style={{cursor: 'pointer'}}
                                >
                                  <span style={circleStyle}>!</span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {getDiscount().quantity > 0 ? (
                      <div className='complete-product'>
                        <div className='container-name-description'>
                          <div className='name-product'>
                            {getDiscount().quantity > 0 ? getDiscount().name : null}
                          </div>
                          <div className='name-description'>
                            {getDiscount().quantity > 0 ? getDiscount().description : null}
                          </div>
                        </div>
                        {getDiscount().quantity > 0 && (
                          <div className='container-price-quantity'>
                            <div className='quantity-product'>
                              Cantidad: {getDiscount().quantity}
                            </div>
                            <div className='price-product'>
                              CAD {formatter.format(getDiscount().totalFee)}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div className='complete-product'>
                      <div className='container-name-description'>
                        <div className='name-product'>
                          {getTotalCanadaTaxFee() > 0 ? (
                            <p style={{fontWeight: 'bold'}}>Servicio Impuesto Canadiense</p>
                          ) : null}
                        </div>
                        <div className='name-description'>
                          {getTotalCanadaTaxFee() > 0 ? (
                            <p style={{fontWeight: 'bold'}}>
                              Tarifa adicional del 13% si el solicitante principal se encuentra en
                              Canada
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {getTotalCanadaTaxFee() > 0 && (
                        <div className='container-price-quantity'>
                          <div className='price-product'>
                            CAD {formatter.format(getTotalCanadaTaxFee())}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='complete-product'>
                      <div className='container-name-description'>
                        <div className='name-product'>
                          {getTotalTransactionFee() > 0 ? (
                            <p style={{fontWeight: 'bold'}}>Servicio Costo de la transacción</p>
                          ) : null}
                        </div>
                        <div className='name-description'>
                          {getTotalTransactionFee() > 0 ? (
                            <p style={{fontWeight: 'bold'}}>
                              4% del monto total, tarifa aplicada por la plataforma de pago
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {getTotalTransactionFee() > 0 && (
                        <div className='container-price-quantity'>
                          <div className='price-product'>
                            CAD {formatter.format(getTotalTransactionFee())}
                          </div>
                        </div>
                      )}
                    </div>

                    {principalUsed()?.wantValidationLmiaExemptJobOffer === 'no' &&
                    principalUsed()?.typeOfClosedWorkPermit ===
                      'ESTÁ EXENTA de una evaluación de impacto del mercado laboral' ? (
                      <div className='rejected-doc mt-2 mb-2'>
                        <div className='flex flex-row items-start gap-4'>
                          <div className='checkbox-red-continer'>
                            <div className='checkbox-red'>
                              <img src={WarningIcon} alt='WarningIcon' />
                            </div>
                          </div>
                          <div className='flex flex-col'>
                            <h1 style={{color: '#9D5425', fontSize: '16px'}}>IMPORTANTE</h1>
                            <p style={{color: '#9D5425', fontSize: '14px'}}>
                              No incluye solicitud de código exento de LMIA.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {principalUsed()?.typeOfClosedWorkPermit ===
                    'REQUIERE de una evaluación de impacto del mercado laboral' ? (
                      <div className='rejected-doc mt-2 mb-2'>
                        <div className='flex flex-row items-start gap-4'>
                          <div className='checkbox-red-continer'>
                            <div className='checkbox-red'>
                              <img src={WarningIcon} alt='WarningIcon' />
                            </div>
                          </div>
                          <div className='flex flex-col'>
                            <h1 style={{color: '#9D5425', fontSize: '16px'}}>IMPORTANTE</h1>
                            <p style={{color: '#9D5425', fontSize: '14px'}}>
                              No incluye solicitud de LMIA.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                        flexWrap: 'wrap',
                        gap: '7px',
                      }}
                    >
                      <p>Esta cotización es válida por 90 días</p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        <button
                          onClick={() => generatePDF()}
                          style={{
                            color: '#60C9E8',
                            fontStyle: 'italic',
                            textDecoration: 'underline',
                            marginLeft: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2px',
                          }}
                        >
                          Descargar
                          <img style={{height: '16px'}} src={pnglogo}></img>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className='containerbackandnextbuttons'
                    style={{
                      marginBottom: '2rem',
                      marginTop: '2rem',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      flexDirection: 'row',
                    }}
                  >
                    <div>
                      <Button
                        onClick={goBack}
                        className='backbutton'
                        style={{
                          width: '16rem',
                          height: '3rem',
                          fontSize: '0.8rem',
                        }}
                      >
                        Volver a cotizar
                      </Button>
                    </div>
                    <div>
                      {showThanks === true && (
                        <iframe
                          width='0'
                          height='0'
                          src={url}
                          title='YouTube video player'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe>
                      )}
                      {showThanks === true ? (
                        <Button
                          className='startbutton-disc2FamGroup-firmar'
                          style={{
                            width: '18rem',
                            backgroundColor: 'gray',
                            color: 'white',
                            height: '3rem',
                            fontSize: '0.8rem',
                          }}
                        >
                          ¡Contrato enviado!
                        </Button>
                      ) : (
                        <div style={{width: '18rem'}}>
                          <Button
                            onClick={() => {
                              handleThanks()
                              IwantContract()
                            }}
                            className='startbutton-disc2FamGroup-firmar'
                            style={{
                              width: '18rem',
                              backgroundColor: '#2CD673',
                              color: 'white',
                              height: '3rem',
                              fontSize: '0.8rem',
                            }}
                          >
                            ¡Solicitar contrato!
                          </Button>
                          <p style={{fontSize: '12px', fontStyle: 'italic'}}>
                            Al hacer clic en este botón, recibirá un contrato en su correo
                            electrónico enviado por Immiland Law.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Table style={{display: 'none'}}>
                <TableBody>
                  <TableRow>
                    <CalculateBiometrics products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateVisitorVisa products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateVisitorFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateVisitorRecord products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateVisitorRecordFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateTransitVisa products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateVisitorLessThanSixMonths products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateTransitVisaFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSuperVisa products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSuperVisaFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependantLessFive products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependantStudyOverFiveExtention products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependantStudylessFiveExtention products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyLanguagePermitExtensionON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpouseOpenWorkPermitExtentionON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateTravelDocumentRenovation products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyPermitExtensionON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSubmission products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateEta products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateEtaFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyPermit products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQStudent products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQExtentionStudent products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatAtipFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyPermitFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyPermitLang products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatExpiredPermit products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateATIP products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatExpiredRestoration products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateExpiredPermitMoreThan90Days products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateStudyPermitStream products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependantPlusFive products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePartnerOpenWorkSpouseON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePartnerWorkPermitPOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQStudentGovernmentFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQMinor products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQMinorGovernmentFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQWorker products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCAQWorkerGovernmentFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCoopWorkPermitInternship products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitHoliday products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateOpenWorkPermitHolderFederal products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitIncludesExtentionFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSponsorshipApplicationIn products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePermanentResidenceSponsorshipFederalFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePostGraduationWorkPermitPOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePostGraduationWorkPermitON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePostGraduateWorkPermitExtention products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateOpenWorkPermitMainApplicant products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCSQ products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCSQgovernmentApplicationFeeMainApplicant products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCSQGovernmentApplicationFeeDependentChild products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePermanentResidenceSponsorshipFederalFeeDepentantChildIncluded
                      products={data}
                    />
                  </TableRow>
                  <TableRow>
                    <CalculateExpressEntryExpressionOfInterestPhase1 products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpousePartnerExpressEntryExpressionOfInterestPhase1 products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSponsorshipApplicationOut products={data} />
                  </TableRow>
                  <TableRow>
                    <LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee
                      products={data}
                    />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitExemptON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitExemptONInsideCanada products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitExemptPOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateOpenWPVulnerable products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitPositiveON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateWorkPermitPositivePOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateLMIAExExtentionON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateLMIAExExtentionPOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateLMIAPosExPOE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateLMIAPosExtentionON products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateQCsponsorship products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateQCsponshorshipFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePermanentResidenceSponsorshipFeeParents products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateQCAddMember products={data} />
                  </TableRow>{' '}
                  <TableRow>
                    <CalculateWorkPermitHolidayFee products={data} />
                  </TableRow>
                  <TableRow>
                    <InternationalExperienceCanadaWorkPermit products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSponsorDependantChild products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpouseTwo products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependantChildExEntryPhaseTwo products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateExEntryPhaseTwo products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePREcFee products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePReconomicSpouse products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePReconomicDepChild products={data} />
                  </TableRow>
                  <TableRow>
                    <Calculaterpcsq products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependendPN products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePN products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculatePNProgram products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpousePN products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpouseOtherPrFederal products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDependentOtherPrFederal products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculaterswpPAPHASEONE products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpouseRSWPphaseone products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCSQDependChild products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCSQpr products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateOtherPrFederal products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpouseParnerFeeCSQ products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateSpousePartnerCSQ products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateEmploymentLetter products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCitizenship products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDocumentAmendment products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDocumentReplacement products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateDocumentReplacementGF products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCitizenshipAdult products={data} />
                  </TableRow>
                  <TableRow>
                    <CalculateCitizenshipMinor products={data} />
                  </TableRow>
                  <TableRow>
                    <DiscountCode />
                  </TableRow>
                  <TableRow>
                    <CalculateCanadaTaxFee />
                    {/* voy por aqui */}
                  </TableRow>
                  <TableRow>
                    <CalculateTransaccionFee />
                  </TableRow>
                  <Resumen
                    products={data}
                    setMatterFromResumeComponent={setMatterFromResumeComponent}
                    setApplicantInfoMatterFromResumeComponent={
                      setApplicantInfoMatterFromResumeComponent
                    }
                  />
                  {/* 				<ResumenWork /> */}
                  <TableRow className='flex items-center'>
                    <CalculateTotalsNew />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        </div>
        <footer className='footer-style'>
          <div className='footer-style-continer'>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>¿Quienes somos?</strong>
              </h3>
              <a href='https://www.immilandcanada.com/quienes-somos'>Historia y Valores</a>
              <a href='https://www.immilandcanada.com/equipo-immiland'>Nuestro Equipo</a>
              <a href='https://www.immilandcanada.com/testimonios'>Testimonios</a>
              <a href='https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#'>
                Preguntas Frecuentes
              </a>
              <a href='https://www.immilandcanada.com/terminos-y-condiciones'>
                <strong>Términos y condiciones.</strong>
              </a>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Servicios</strong>
              </h3>
              <a href='https://www.immilandcanada.com/migracion/inicio'>Migración</a>
              <a href='https://www.immilandcanada.com/reclutamiento/inicio'>Trabajo</a>
              <a href='https://www.immilandcanada.com/educacion/inicio'>Educacion</a>
              <a href='https://www.immilandcanada.com/traduccion/inicio'>Traducciones</a>
              <a href='https://www.immilandcanada.com/establecimiento/inicio'>Establecimiento</a>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Ubicación</strong>
              </div>
              <div>
                <a href='https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share'>
                  <div>
                    2025 Rue Parthenais
                    <br />
                    suit 320
                    <br />
                    Montreal, Quebec,
                    <br />
                    Canadá
                  </div>
                </a>
              </div>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Blog</strong>
              </h3>
              <div>
                <a href='https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio'>
                  <div>
                    ¿Puedo viajar fuera <br />
                    de Canada mientras estudio?
                  </div>
                </a>
              </div>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>¡Sigamos en contacto!</strong>
                </h3>
                <div>
                  <span>Info@immilandcanada.com</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    paddingRight: '2rem',
                    alignItems: 'center',
                  }}
                >
                  <a href='https://www.youtube.com/immiland'>
                    <img
                      src='https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png'
                      alt=''
                    />
                  </a>
                  <a href='https://www.instagram.com/immilandcanada/'>
                    <img src='https://img.icons8.com/windows/25/ffffff/instagram-new.png' alt='' />
                  </a>
                  <a href='https://www.facebook.com/immilandcanada'>
                    <img
                      src='https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png'
                      alt=''
                    />
                  </a>
                  <a href='https://www.tiktok.com/@immilandcanada'>
                    <img src='https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png' alt='' />
                  </a>
                  <a href='https://ca.linkedin.com/company/immiland-canada'>
                    <img
                      src='https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png'
                      alt=''
                    />
                  </a>
                  <a href='https://twitter.com/immilandcanada'>
                    <img src='https://img.icons8.com/material/25/ffffff/twitter--v2.png' alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  } else {
    return <h1>no se detectaron productos</h1>
  }
}

export default observer(EndModule)
