
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'


const DiscountCode = () => {
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getPGWPEON,getPGWPEx, getTotalProfessionalFeesClean, setDiscount, getDiscount, principalUsed, SponsorshipApplicationOut, SponsorshipApplicationIn, QCsponsorship, getsetCalculatestudyPermitAcademicDetails, getPartnerOpenWorkSpouse, getWorkPermitExemptONDetailsInsideCanada, getLMIAPosExtentionON, getWorkPermitExemptONDetails, getLMIAExExtentionON, getStudyPermitExtentionON, getSpousePartnerOpenWorkPermitExtentionON } =
		rootStore.familyStore

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})

	const conditions = [
		getsetCalculatestudyPermitAcademicDetails(),
		getPartnerOpenWorkSpouse(),
		getWorkPermitExemptONDetailsInsideCanada(),
		getLMIAPosExtentionON(),
		getWorkPermitExemptONDetails(),
		getLMIAExExtentionON(),
		getStudyPermitExtentionON(),
		getSpousePartnerOpenWorkPermitExtentionON(),
		getPGWPEON(),
		getPGWPEx()
	];
	
	// Count how many have quantity > 0
	const validCount = conditions.filter(item => item && item.quantity > 0).length;
	console.log("Valid count: ",validCount);

	familyMembers.map((member) => {
		if (((member?.discountCode?.trim() === 'AMOR' ||
			member?.discountCode?.trim() === 'amor')
			&&
			((SponsorshipApplicationOut && SponsorshipApplicationOut.quantity > 0) ||
				(SponsorshipApplicationIn && SponsorshipApplicationIn.quantity > 0) ||
				(QCsponsorship && QCsponsorship.quantity > 0) || validCount >= 2
			)
			
		) && getDiscount().totalFee <= 0) {
			console.log("Aca voy pasando", getTotalProfessionalFeesClean() * 0.2)
			console.log("DiscountCode: ", member.discountCode)
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.2,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.2,
			})
		} else if ((member?.discountCode?.trim() === 'CANADACONANDY'
			|| member?.discountCode?.trim() === 'canadaconandy'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 250,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 250,
			})
		} else if ((member?.discountCode?.trim() === 'CANADACONMARTHA'
			|| member?.discountCode?.trim() === 'canadaconmartha'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 250,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 250,
			})
		} else if ((member?.discountCode?.trim() === 'CANADACONBRITTNEY'
			|| member?.discountCode?.trim() === 'canadaconbrittney'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 250,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 250,
			})
		} else if ((member?.discountCode?.trim() === 'CANADACONEDNA'
			|| member?.discountCode?.trim() === 'canadaconedna'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 250,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 250,
			})
		} else if ((member?.discountCode?.trim() === 'CANADACONCARO'
			|| member?.discountCode?.trim() === 'canadaconcaro'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 300,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 300,
			})
		}
		else if ((member?.discountCode?.trim() === 'CANADACONODETA'
			|| member?.discountCode?.trim() === 'canadaconodeta'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 250,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 250,
			})
		}
		else if ((member?.discountCode?.trim() === 'CANADACONLAURA'
			|| member?.discountCode?.trim() === 'canadaconlaura'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 350,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 350,
			})
		}
		else if ((member?.discountCode?.trim() === 'CANADACONERICA'
			|| member?.discountCode?.trim() === 'canadaconerica'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 350,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 350,
			})
		}
		else if ((member?.discountCode?.trim() === 'CANADACONEDDY'
			|| member?.discountCode?.trim() === 'canadaconeddy'
		) && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 450,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 450,
			})
		} else if ((member?.discountCode?.trim() === 'EDUCATIONTODAY' || member?.discountCode?.trim() === 'educationtoday') && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.2,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.2,
			})
		} else if (getDiscount().totalFee <= 0 && principalUsed()?.inCanada === "si" && principalUsed()?.discountCode?.trim() === 'INCANADA') {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.2,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.2,
			})
		} else if (member.isPreviousClient === "si" && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.3,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.3,
			})

		} else if (principalUsed()?.isPreviousClient !== "si" && principalUsed()?.inCanada === "si" && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.1,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.1,
			})
		} else if (member?.discountCode?.trim() === 'PLANETAJUANCANADAIMMILAND2023' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.05,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.05,
			})
		} else if (member?.discountCode?.trim() === 'ATIPRECHAZOCAN' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.1,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.1,
			})
		} else if (member?.discountCode?.trim() === 'ANIVERSARIO4' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.1,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.1,
			})
		} else if (member?.discountCode?.trim() === 'Sueño2023' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.15,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.15,
			})
		} else if (member?.discountCode?.trim() === 'EVENTOBOGOTAOCT2023' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.3,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.3,
			})
		} else if (member?.discountCode?.trim() === 'SERVICIOSADICIONALES/IMM' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.1,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.1,
			})
		} else if (member?.discountCode?.trim() === 'IMM$/CONSULTAPREVIA' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: 200,
				name: "Codigo de descuento",
				quantity: 1,
				fee: 200,
			})
		} else if (member?.discountCode?.trim() === 'Bluecanada20' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.2,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.2,
			})
		} else if (member?.discountCode?.trim() === 'BOXINGWEEK' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.2,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.2,
			})
		} else if (member?.discountCode?.trim() === 'EDUCATION' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.3,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.3,
			})
		} else if (member?.discountCode?.trim() === 'EBSC' && getDiscount().totalFee <= 0) {
			setDiscount({
				id: "",
				productContratationId: "",
				description: "",
				totalFee: getTotalProfessionalFeesClean() * 0.05,
				name: "Codigo de descuento",
				quantity: 1,
				fee: getTotalProfessionalFeesClean() * 0.05,
			})
		}
	})

	return (
		<>
			{getDiscount().totalFee > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						Descuento
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>{' '}

					</TableCell>
					<TableCell></TableCell>
					<TableCell className='border-b border-gray-100'>
						<Span>Precio </Span>CAD -{formatter.format(getDiscount().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(DiscountCode)
