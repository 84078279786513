/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateDocumentReplacementGF: React.FC<IProps> = ({
	products
}) => {
	const product = products.find(element => element.contratationProductId == "108")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getDocumentReplacementGF, setDocumentReplacementGF} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
    const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
console.log("entre acaaaa ",JSON.stringify(familyMembers))


	
	if (product !== undefined && member.typeOfApplication === "Reemplazo de documento extraviado") {
		console.log("estoy por aca: producto: ", product)
		setDocumentReplacementGF({
			id: product.id,
			productContratationId: product.contratationProductId,
			description: product.description,
			totalFee: product.fee,
			name: product.name,
			quantity: 1,
			fee: product.price,
			memberInfo:arrayOfMembers,
			type:'pf'
		})
	}})

	return (
		<>
			{getDocumentReplacementGF().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getDocumentReplacementGF().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getDocumentReplacementGF().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getDocumentReplacementGF().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getDocumentReplacementGF().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDocumentReplacementGF)
